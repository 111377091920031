import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from "@mui/material";
import Container from '@mui/material/Container';
import * as CategoriesAction from "../actions/CategoriesAction"
import * as ProductsAction from "../actions/ProductsAction"
import { useHistory } from "react-router-dom";
import Alert from '@mui/material/Alert';

const ForceRefreshData = (props) => {
  const history = useHistory();
  const [alertMessage, setAlertMessage] = useState('Click on REFRESH button to get the latest data.');
  const [alertSeverity, setAlertSeverity] = useState('info');

  const refreshData = () => {
    setAlertSeverity('info');
    setAlertMessage('Click on REFRESH button to get the latest data.');
    props.loadProducts()
      .then(tempProducts => {
        setAlertSeverity('success');
        setAlertMessage('Data was refreshed successfully !');
      })
      .catch(error => {
        let message = 'There was an error in loading Products';
        setAlertMessage(message);
        setAlertSeverity('error');
        console.error(message, error);
      });
    props.loadCategories()
      .then(tempCategories => {
        setAlertSeverity('success');
        setAlertMessage('Data was refreshed successfully !');
      })
      .catch(error => {
        let message = 'There was an error in loading Categories';
        setAlertSeverity('error');
        setAlertMessage(message);
        console.error(message, error);
      });
  }

  const navigateToHome = () => {
    history.push('/');
  }

  const renderRefreshDataPage = () => {
    return (
      <Box sx={{ m: 5 }}>
        <Typography variant="h4" sx={{ textTransform: 'uppercase' }} display="block" gutterBottom>
          {'Refresh Data'}
        </Typography>
        <Alert severity={alertSeverity} sx={{ my: 3}}>{alertMessage}</Alert>
        <Typography variant="body2" sx={{ my: 3, mx: 3 }} display="block" gutterBottom>
          {'Products Count -' + props.products.length}
        </Typography>
        <Typography variant="body2" sx={{ my: 3, mx: 3 }} display="block" gutterBottom>
          {'Categories Count -' + props.categories.length}
        </Typography>
        <Button variant="outlined" sx={{ my: 1 }} onClick={() => { navigateToHome() }}>
          Back to Home
        </Button>
        <Button variant="contained" sx={{ my: 1, mx: 2 }} onClick={() => { refreshData() }}>
          Refresh
        </Button>
      </Box>
    );
  };

  return (
    <Container maxWidth="sm">
      {renderRefreshDataPage()}
    </Container>
  );
};



const mapStateToProps = state => ({
  categories: state.categories,
  products: state.products,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadProducts: ProductsAction.loadProducts,
  loadCategories: CategoriesAction.loadCategories,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForceRefreshData);