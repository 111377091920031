import React from 'react';
import { Switch, Route } from "react-router-dom";
import NotFound from "../components/NotFound";
import Home from "../components/Home";
import AddProduct from "../components/AddProduct";
import ProductList from "../components/ProductList";
import AdminProductList from "../components/AdminProductList";
import AddCategory from "../components/AddCategory";
import CategoryList from "../components/CategoryList";
import CategoryDetails from '../components/CategoryDetails';
import ProductDetails from '../components/ProductDetails';
import AboutUs from "../components/AboutUs"
import ContactUs from "../components/ContactUs"
import CorporateVideo from "../components/CorporateVideo"
import Services from "../components/Services"
import FinancialDetails from "../components/FinancialDetails"
import SignInRequired from "../components/SignInRequired"
import ProtectedRoute from './ProtectedRoute';
import {Container } from "@mui/material";
import SignIn from 'components/SignIn';
import AdminProductDetails from 'components/AdminProductDetails';
import DowloadEntities from "components/DowloadEntities"
import ForceRefreshData from "components/ForceRefreshData"
import UploadFile from 'components/UploadFile';
import Sitemap from 'components/Sitemap';

const Router = (props) => {
  return (
    <Container className='routerContainer' sx={{fixed: {xs: true, md: false}, bgcolor:'background.paper'}} disableGutters >
      <Switch>
        <Route exact path={["/", "/home"]} component={Home} />
        <ProtectedRoute exact path="/manage-products" component={AdminProductList} />
        <ProtectedRoute exact path="/admin-product-details" component={AdminProductDetails} />
        <ProtectedRoute exact path="/add-product" component={AddProduct} />
        <ProtectedRoute exact path="/add-category" component={AddCategory} />
        <ProtectedRoute exact path="/categories" component={CategoryList} />
        <ProtectedRoute exact path="/category-details" component={CategoryDetails} />
        <ProtectedRoute exact path="/download-entities" component={ownProps => <DowloadEntities {...ownProps} showSnackMessage={props.showSnackMessage}  />}  />
        
        <ProtectedRoute exact path="/force-refresh-data" component={ownProps => <ForceRefreshData {...ownProps} showSnackMessage={props.showSnackMessage}  />}  />
        <ProtectedRoute exact path="/upload-file" component={ownProps => <UploadFile {...ownProps} showSnackMessage={props.showSnackMessage}  />}  />
        <Route exact path="/products" component={ProductList} />
        <Route exact path="/product/:productName" component={ProductDetails} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/corporate-video" component={CorporateVideo} />
        <Route exact path="/contact-us" component={ownProps => <ContactUs {...ownProps} showSnackMessage={props.showSnackMessage} customParam="customParamVal" />} />
        <Route exact path="/financial-details" component={FinancialDetails} />
        <Route exact path="/sitemap" component={Sitemap} />
        <Route exact path="/signin-required" component={SignInRequired} />
        <Route exact path="/login" component={ownProps => <SignIn {...ownProps} showSnackMessage={props.showSnackMessage} customParam="customParamVal" />} />
        <Route component={NotFound} />
      </Switch>
    </Container>
  );
};

export default Router;