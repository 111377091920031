import React from 'react';
import { Box, Container, Button } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { pink } from '@mui/material/colors';
// import { useAuth } from "../hooks/useAuth";
// import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

const SignInRequired = (props) => {
  // const auth = useAuth();
  const history = useHistory();

  const handleLogin = () => {
    history.push('login',{ referrer: props.location.state.referrer });
  };

  return (
    <Container maxWidth="sm" disableGutters
      sx={{
        width: { xs: 'auto', md: 500 },
        display: 'block',
        marginTop: { xs: 10, md: 3 },
        padding: { xs: 1, md: 3 },
        [(theme) => theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      }}>
      <CssBaseline />
      <Paper elevation={3}
        sx={{
          margin: { xs: 1, md: 3 },
          padding: { xs: 1, md: 3 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Avatar
          sx={{
            marginTop: { xs: 1, md: 1 },
            marginBottom: { xs: 1, md: 1 },
            bgcolor: pink[500]
          }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Restricted Page
        </Typography>
        <Box sx={{
          margin: { xs: 1, md: 3 },
        }}>
          <Typography component="div" variant="subtitle">
          The page you are trying to access needs Administator privileges.
          </Typography>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleLogin()}
            sx={{ marginTop: { xs: 1, md: 3 } }}
          >
            Sign in as Administator
          </Button>
        </Box>
      </Paper>
    </Container>



    /*
    auth.user ? <div>
      The page you are trying to access needs Administator privileges.
      Please click <a href="google.com" >here</a> to login as Administator.
    </div> : <Redirect
        to={{
          pathname: props.location.state.referrer
        }}
      />
      */
  );
};

export default SignInRequired;