import React from 'react';
// import { Box} from '@mui/material';
// import { Typography } from "@mui/material";
import Container from '@mui/material/Container';

const Services = () => {
  return (
    <Container maxWidth="md" disableGutters>
      <>
        {/* <div className="wrap mcb-wrap mcb-wrap-d22c42262 one-third  valign-top clearfix" >
          <div className="mcb-wrap-inner">
            <div className="column mcb-column mcb-item-80fdc3851 one column_column">
              <Box className="column_attr clearfix align_center" sx={{ width: '400px', height: '480px', my: 0, mx: 'auto', fontSize: 12, color: '#9b8143', border: '4px solid #9b8143;' }} >
                <Typography variant="h3" display="block" sx={{ mx: 2, my: 3, color: '#9b8143', textAlign: 'center', fontSize: 40 }} gutterBottom>{'Quality Product Range'}</Typography>
                <hr className="no_line"  />
                <Typography variant="h3" display="block" sx={{ mx: 2, my: 4, color: '#9b8143', textAlign: 'center', fontSize: 13 }} gutterBottom>
                  Nio Equipment & Engineering is built on a strong foundation of integrity. And our reputation for quality, reliability and innovation is represented by the range our products. We remain keenly focused on enhancing our unique technology differentiation - and delivering integrated, value-added solutions.
                </Typography>
                <hr className="no_line" />
                <div className="image_frame image_item no_link scale-with-grid alignnone no_border">
                  <Box className="image_wrapper" sx={{mx:'auto',  mt: '30px', width:100}}>
                    <img className="scale-with-grid" src="https://www.rudraequipment.in/wp-content/uploads/2019/12/quality.png" alt="" title="" />
                  </Box>
                </div>
              </Box>
            </div>
          </div>
        </div> */}
        <div className="wrap mcb-wrap mcb-wrap-01c812e66 one  valign-top clearfix" >
          <div className="mcb-wrap-inner">
            <div className="column mcb-column mcb-item-db6324600 one column_column">
              <div className="column_attr clearfix align_center" >
                <h2> Our Services </h2></div>
            </div>
            <div className="column mcb-column mcb-item-4f391555f one column_divider">
              <hr className="no_line" sx={{ margin: '0' }} /> </div>
          </div>
        </div>
        <div className="wrap mcb-wrap mcb-wrap-85da740d6 one-second  valign-top clearfix" >
          <div className="mcb-wrap-inner">
            <div className="column mcb-column mcb-item-d41b119d2 one-third column_image">
              <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                <div className="image_wrapper"><img className="scale-with-grid" src="https://www.rudraequipment.in/wp-content/uploads/2019/12/4.png" alt="" title="" /></div>
              </div>
            </div>
            <div className="column mcb-column mcb-item-6daaaa105 two-third column_column">
              <div className="column_attr clearfix" >
                <h3>Spare Parts</h3>
                <p className="big">With escalating downtime costs and tight delivery schedules, you can't afford to wait for replacement parts.</p>
                <p>All the parts are stocked at our service centers and can be shipped anywhere in the world within 24 hours of order receipt. </p>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap mcb-wrap mcb-wrap-ef7b159ae one-second  valign-top clearfix" >
          <div className="mcb-wrap-inner">
            <div className="column mcb-column mcb-item-1759c28d9 one-third column_image">
              <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                <div className="image_wrapper"><img className="scale-with-grid" src="https://www.rudraequipment.in/wp-content/uploads/2019/12/2.png" alt="" title="" /></div>
              </div>
            </div>
            <div className="column mcb-column mcb-item-54a484cd8 two-third column_column">
              <div className="column_attr clearfix" >
                <h3>Annual Repair Agreement</h3>
                <p className="big">Annual Repair Agreement makes it easier to forecast spending and helps lower your everyday costs. This preventive service enables flexible payment options, including a fixed monthly amount for your automation equipment repairs. </p>
                <p> So drive down your repair costs while improving operational performance with an Annual Repair Agreement from Nio Equipment & Engineering. </p>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap mcb-wrap mcb-wrap-54f6fcc45 divider  valign-middle clearfix" >
          <div className="mcb-wrap-inner"></div>
        </div>
        <div className="wrap mcb-wrap mcb-wrap-cb0935e8a one-second  valign-top clearfix" >
          <div className="mcb-wrap-inner">
            <div className="column mcb-column mcb-item-dcef226de one-third column_image">
              <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                <div className="image_wrapper"><img className="scale-with-grid" src="https://www.rudraequipment.in/wp-content/uploads/2019/12/3.png" alt="" title="" /></div>
              </div>
            </div>
            <div className="column mcb-column mcb-item-0d9c27e1d two-third column_column">
              <div className="column_attr clearfix" >
                <h3>Support Team</h3>
                <p className="big">We are eager to support you to ensure the smooth operations at your end. There are times when you need just a little help to keep up with your production. We understand that sense of urgency. </p>
                <p>Nio Equipment & Engineering has developed a support team that gives you that required guidance over a phone call. So be it a complain, concern or an inquiry. Just call us.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap mcb-wrap mcb-wrap-a434b5082 one-second  valign-top clearfix" >
          <div className="mcb-wrap-inner">
            <div className="column mcb-column mcb-item-fdffb7800 one-third column_image">
              <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                <div className="image_wrapper"><img className="scale-with-grid" src="https://www.rudraequipment.in/wp-content/uploads/2019/12/1.png" alt="" title="" /></div>
              </div>
            </div>
            <div className="column mcb-column mcb-item-2388f6a98 two-third column_column">
              <div className="column_attr clearfix" >
                <h3>On Site Service </h3>
                <p className="big">If you do not know, How to deal with the Machine, when it acts funny. Nio Equipment & Engineering has the technical expertise to help you increase uptime and optimize equipment performance.</p>
                <p>Available on an as-needed, scheduled, or full-time basis, we help you meet your specific needs.</p>
              </div>
            </div>
          </div>
        </div>
      </>
    </Container>
  );
};

export default Services;