import React, { useState } from "react";
import { Container, Stack, TextField, Link, Button } from "@mui/material";
import emailjs from 'emailjs-com'
import { Box } from "@mui/system";
import Typography from '@mui/material/Typography';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { deepOrange } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import SendIcon from '@mui/icons-material/Send';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

const ContactUs = (props) => {
  const [name, setName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [city, setCity] = useState(undefined);
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState(undefined);

  const resetForm = () => {
    setName('');
    setEmail('');
    setPhone('');
    setCity('');
    setEmailError(false);
    setMessage('');
  };

  const handleChangeEmail = (e) => {
    if (
      !e.target.value.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setEmail(e.target.value);
      setEmailError(true);

      if (email === "") {
        // check if the input is empty
        setEmailError(false);
      }
    } else {
      setEmail(e.target.value);
      setEmailError(false);
    }
  };

  const sendInquiry = () => {
    if (name && email && phone && message && city) {
      const serviceId = 'service_03x2bdt';
      const templateId = 'template_zllnrj8';
      const userId = 'user_VPzUZ87s2iw4M79yMShgL';
      const templateParams = {
        from_name: name,
        from_email: email,
        from_phone: phone,
        from_city: city,
        message: message,
        to_name: 'Nio Equipment & Engineering',
        to_email: 'nio.equipment.engg@gmail.com',
        reply_to: email,

      };

      emailjs.send(serviceId, templateId, templateParams, userId)
        .then(response => {
          console.log(response);
          props.showSnackMessage({
            vertical: 'top',
            horizontal: 'center',
            snackMessage: 'Inquiry sent successfully for : ' + email,
            severity: 'success'
          });
          resetForm();
        })
        .then(error => console.log(error));
    } else {
      alert('Please fill in all fields.');
    }
  };

  const renderInquiryPage = () => {
    return (
      <Container maxWidth="lg" disableGutters sx={{ my: 3 }}>
        <Stack sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-around' }}>
          <Box  sx={{ width : { xs: '100%', md: '50%' }}}>
            <Typography variant="h4" display="block" gutterBottom sx={{ ml: { xs: 2, md: 3 }, mb: 3 }}>
              {'Contact'}
            </Typography>
            <Stack sx={{ display: 'flex', m: { xs: 2, md: 3 }, flexDirection: { xs: 'row', md: 'row' } }}>
              <Link href="tel:+917020611480">
                <Avatar sx={{ width: 64, height: 64, bgcolor: deepOrange[500] }} variant="square">
                  <PhoneInTalkIcon sx={{ fontSize: 40 }} />
                </Avatar>
              </Link>
              <Box sx={{ mx: 2 }}>
                <Link sx={{ typography: 'body2', fontSize: { xs: "20px", md: "25px" }, my: 1, color: 'inherit', textDecoration: 'none' }} href="tel:+917020611480">{'+91-7020-611480'}</Link>
                <Typography variant="subtitle" display="block" gutterBottom>
                  {'Sales Number'}
                </Typography>
              </Box>
            </Stack>

            <Stack sx={{ display: 'flex', m: { xs: 2, md: 3 }, flexDirection: { xs: 'row', md: 'row' } }}>
              <Link href="tel:+919834964986">
                <Avatar sx={{ width: 64, height: 64, bgcolor: deepOrange[500] }} variant="square">
                  <PhoneInTalkIcon sx={{ fontSize: 40 }} />
                </Avatar>
              </Link>
              <Box sx={{ mx: 2 }}>
                <Link sx={{ typography: 'body2', fontSize: { xs: "20px", md: "25px" }, my: 1, color: 'inherit', textDecoration: 'none' }} href="tel:+919834964986">{'+91-9834-964986'}</Link>
                <Typography variant="subtitle" display="block" gutterBottom>
                  {'Service Number'}
                </Typography>
              </Box>
            </Stack>

            <Stack sx={{ display: 'flex', m: { xs: 2, md: 3 }, flexDirection: { xs: 'row', md: 'row' } }}>
              <Link href="mailto:info@nioequipment.in">
                <Avatar sx={{ width: 64, height: 64, bgcolor: deepOrange[500] }} variant="square">
                  <MailIcon sx={{ fontSize: 40 }} />
                </Avatar>
              </Link>
              <Box sx={{ mx: 2 }}>
                <Link sx={{ typography: 'body2', fontSize: { xs: "20px", md: "25px" }, my: 1, color: 'inherit', textDecoration: 'none' }} href="mailto:info@nioequipment.in">{'info@nioequipment.in'}</Link>
                <Typography variant="subtitle" display="block" gutterBottom>
                  {'Maximum reply within 8 hrs'}
                </Typography>
              </Box>
            </Stack>

            <Stack sx={{ display: 'flex', m: { xs: 2, md: 3 }, flexDirection: { xs: 'row', md: 'row' } }}>
              <Link component="button" sx={{ width: 64, height: 64 }} onClick={() => { window.open('https://www.google.com/maps/place/Nio+Equipment+And+Engineering/@18.6351588,73.8281625,17z/data=!3m1!4b1!4m5!3m4!1s0x3bc2b9c9970f0b99:0x41a12f1e262aeb85!8m2!3d18.6351537!4d73.8303512', '_blank', 'noopener,noreferrer') }}>
                <Avatar sx={{ width: 64, height: 64, bgcolor: deepOrange[500] }} variant="square">
                  <LocationOnOutlinedIcon sx={{ fontSize: 40 }} />
                </Avatar>
              </Link>
              <Box sx={{ mx: 2 }}>
                <Link sx={{ typography: 'body2', fontSize: { xs: "20px", md: "25px" }, my: 1, color: 'inherit', textDecoration: 'none' }} onClick={() => { window.open('https://www.google.com/maps/place/Nio+Equipment+And+Engineering/@18.6351588,73.8281625,17z/data=!3m1!4b1!4m5!3m4!1s0x3bc2b9c9970f0b99:0x41a12f1e262aeb85!8m2!3d18.6351537!4d73.8303512', '_blank', 'noopener,noreferrer') }}>{'Nio Equipment & Engineering'}</Link>
                <Link underline="none" sx={{ display: 'block', typography: 'subtitle', color: 'inherit' }} component="button" onClick={() => { window.open('https://www.google.com/maps/place/Nio+Equipment+And+Engineering/@18.6351588,73.8281625,17z/data=!3m1!4b1!4m5!3m4!1s0x3bc2b9c9970f0b99:0x41a12f1e262aeb85!8m2!3d18.6351537!4d73.8303512', '_blank', 'noopener,noreferrer') }} >{'J-318, MIDC, Bhosari'}</Link>
                <Link underline="none" sx={{ typography: 'subtitle', mb: 1, color: 'inherit' }} component="button" onClick={() => { window.open('https://www.google.com/maps/place/Nio+Equipment+And+Engineering/@18.6351588,73.8281625,17z/data=!3m1!4b1!4m5!3m4!1s0x3bc2b9c9970f0b99:0x41a12f1e262aeb85!8m2!3d18.6351537!4d73.8303512', '_blank', 'noopener,noreferrer') }} >{'Maharashtra, Pune - 411026'}</Link>
              </Box>
            </Stack>


          </Box>
          <Box sx={{ width : { xs: '100%', md: '50%' }}}>
            <Typography variant="h4" display="block" gutterBottom sx={{ ml: { xs: 2, md: 5 }, mb: 3 }}>
              {'Have an inquiry?'}
            </Typography>

            <form >
              <Box mb={2} sx={{mx : { xs: 2, md: 5 }}}>
                <TextField
                  placeholder="Enter your name"
                  label="Name"
                  variant="filled"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  type="text"
                  fullWidth
                  autoComplete="name"
                  autoFocus
                  sx={{ mb: 2 }}
                />
                <Stack sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                  <TextField
                    label="Email"
                    placeholder="Enter your email address"
                    variant="filled"
                    value={email}
                    onChange={(e) => handleChangeEmail(e)}
                    error={emailError}
                    required
                    type="email"
                    sx={{ mb: 2, mr: 2, width : { xs: '100%', md: '60%' }}}
                  />
                  <TextField
                    placeholder="Enter your phone"
                    label="Phone"
                    variant="filled"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                    type="text"
                    sx={{ mb: 2, width : { xs: '100%', md: '40%' }}}
                  />
                </Stack>
                <TextField
                  placeholder="Enter Your City"
                  label="Your City"
                  variant="filled"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                  sx={{ mb: 2 }}
                  fullWidth
                />
                <TextField
                  label="Your Message"
                  placeholder="How can we help you?"
                  variant="filled"
                  multiline
                  rows={5}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  type="text"
                  sx={{ mb: 2 }}
                  fullWidth
                />

                <Button variant="outlined" size="large" endIcon={<SendIcon />} onClick={() => { sendInquiry() }}>
                  Send
                </Button>
              </Box>
            </form>
          </Box>
        </Stack>
      </Container>
    );
  }

  const renderLocationMap = () => {
    return (
      <Container maxWidth="lg" disableGutters sx={{ mb: 8 }}>
        <Typography variant="h4" display="block" gutterBottom sx={{ ml: 3, mb: 3 }}>
          {'Get Directions for Nio Equipment & Engineering'}
        </Typography>
        <Card raised={true} sx={{ mx: { xs: 2, md: 3 } }}>
          <CardActionArea>
            <CardMedia
              component="iframe"
              image="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.6421465604753!2d73.82816251498976!3d18.635158770523113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9c9970f0b99%3A0x41a12f1e262aeb85!2sNio%20Equipment%20And%20Engineering!5e0!3m2!1sen!2sin!4v1643672534610!5m2!1sen!2sin"
              sx={{
                height: 500
              }}
              frameBorder="0"
            />
          </CardActionArea>
        </Card>
      </Container>
    );
  };

  return (
    <>
      {renderInquiryPage()}
      {renderLocationMap()}
    </>
  );



};

export default ContactUs;