import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";


function ProtectedRoute({ component: Component, ...restOfProps }) {
  const auth = useAuth();
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        auth.user ? <Component {...props} /> : <Redirect
        to={{
          pathname: "/signin-required",
          search: "?referrer="+restOfProps.location.pathname,
          state: { referrer: restOfProps.location.pathname }
        }}
      />
      }
    />
  );
}

export default ProtectedRoute;