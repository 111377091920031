import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  routerContainer: {
   
  },
  header: {
    backgroundColor: '#fff !important',
    color: '#0c8ced',
    [theme.breakpoints.down('md')]: {
      backgroundColor: '#424040 !important',
      color: '#fff',
    },
    justifyContent: 'center',
    position: 'static !important',
    zIndex: 999,
    height: '60px',
    boxShadow: 'none !important'
  },
  isStickyHeader: {
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important ',
    position: 'fixed !important',
    [theme.breakpoints.down('md')]: {
      position: 'static !important',
      color: '#fff',
    },
    top: 0,
    left: 0,
    height: '60px',
    '& $userSettings': {
      display: 'none'
    },
    '& $trustSealButton': {
      display: 'none'
    },
    '& $headerToolbar': {
      margin: '0 auto',
      width: '80%',
      boxShadow: '1'
    },

  },
  userSettings: {
    display: 'flex'
  },
  trustSealButton: {
    display: 'flex'
  },
  headerToolbar: {
    width: '100%',
    margin: '0'
  },
  topHeader: {
    backgroundColor: "#424040 !important",
    color: '#fff',
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: '#424040 !important',
    color: theme.palette.primary.contrastText,
    width: '85%',
    height: 'auto',
    display: 'flex',
    flexDirection: "row",
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
      width: 'auto',
      height: 'auto',
    },
    justifyContent: "space-around",
    alignItems: 'center',
    textAlign: 'center',
    margin: 'auto',
  }
}));

export default useStyles;