import ActionTypes from './ActionTypes';
import CategoryService from "../services/CategoryService";

/***** Action Creators *****/
const DISPATCH_ADD_CATEGORY = category => ({
    type: ActionTypes.ADD_CATEGORY,
    payload: { category }
});

const DISPATCH_UPDATE_CATEGORY = category => ({
    type: ActionTypes.UPDATE_CATEGORY,
    payload: { category }
});

const DISPATCH_DELETE_CATEGORY = categoryId => ({
    type: ActionTypes.DELETE_CATEGORY,
    payload: { categoryId }
});

const DISPATCH_LOAD_CATEGORIES = categories => ({
    type: ActionTypes.LOAD_CATEGORIES,
    payload: { categories }
});


/***** Actions *****/

export const loadCategories = () => async (dispatch, getState) => {
    try {
        return CategoryService.getSortedCategories()
            .then(tempCategories => {
                // console.log("Got Categories in CategoriesAction : ", tempCategories);
                dispatch(DISPATCH_LOAD_CATEGORIES(tempCategories));
                return Promise.resolve(tempCategories);
            })
            .catch(error => {
                console.error('There was an error!', error);
                return Promise.reject(error);
            });
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const getAllCategories = () => async (dispatch, getState) => {
    try {
        return CategoryService.getSortedCategories()
            .then(tempCategories => {
                // console.log("Got Categories in CategoriesAction : ", tempCategories);
                return Promise.resolve(tempCategories);
            })
            .catch(error => {
                console.error('There was an error!', error);
                return Promise.reject(error);
            });
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const deleteCategory = categoryId => async (dispatch, getState) => {
    try {
        return CategoryService.deleteCategory(categoryId)
            .then(() => {
                // console.log("Category deleted with id : ", categoryId);
                dispatch(DISPATCH_DELETE_CATEGORY(categoryId));
                return Promise.resolve(categoryId);
            })
            .catch(error => {
                console.error('There was an error!', error);
                return Promise.reject(error);
            });
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const addCategory = category => (dispatch, getState) => {
    try {
        return CategoryService.addCategory(category)
            .then((addedCategory) => {
                // console.log("Category added with id : ", addedCategory.id);
                dispatch(DISPATCH_ADD_CATEGORY({...category, id : addedCategory.id }));
                return Promise.resolve({...category, id : addedCategory.id });
            })
            .catch(error => {
                console.error('There was an error!', error);
                return Promise.reject(error);
            });
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const updateCategory = (id, category) => async (dispatch, getState) => {
    try {
        return CategoryService.updateCategory(id, category)
            .then(() => {
                // console.log("Category updated with id : ", id);
                dispatch(DISPATCH_UPDATE_CATEGORY({...category, id : id }));
                return Promise.resolve({...category, id : id });
            })
            .catch(error => {
                console.error('There was an error!', error);
                return Promise.reject(error);
            });
    }
    catch (err) {
        return Promise.reject(err);
    }
}

