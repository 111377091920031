import { db } from "../config/firebase-config";

import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

import { query, orderBy, onSnapshot } from "firebase/firestore";

const categoryCollectionRef = collection(db, "categories");


class CategoryService {

  getAllCategories = () => {
    return getDocs(categoryCollectionRef);
  };

  getAllSortedCategoriesListener = () => {
    const categories = [];
    const q = query(categoryCollectionRef, orderBy("sortOrder"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        categories.push(doc.data());
      });
      console.log("Fetched Categories are: ", categories);
    });
    unsubscribe();
  };

  getSortedCategories = async () => {
    const sortedCategories = [];
    const q = query(categoryCollectionRef, orderBy("sortOrder"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      sortedCategories.push({ ...doc.data(), id: doc.id });
    });
    return sortedCategories;
  };

  addCategory = (newCategory) => {
    return addDoc(categoryCollectionRef, newCategory);
  };

  updateCategory = (id, updatedCategory) => {
    const categoryDoc = doc(db, "categories", id);
    return updateDoc(categoryDoc, updatedCategory);
  };

  deleteCategory = (id) => {
    const categoryDoc = doc(db, "categories", id);
    return deleteDoc(categoryDoc);
  };

  getCategory = (id) => {
    const categoryDoc = doc(db, "categories", id);
    return getDoc(categoryDoc);
  };

}

export default new CategoryService();