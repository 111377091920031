import ActionTypes from './ActionTypes';
import ProductService from "../services/ProductService";

/***** Action Creators *****/
const DISPATCH_ADD_PRODUCT = product => ({
    type: ActionTypes.ADD_PRODUCT,
    payload: { product }
});

const DISPATCH_UPDATE_PRODUCT = product => ({
    type: ActionTypes.UPDATE_PRODUCT,
    payload: { product }
});

const DISPATCH_DELETE_PRODUCT = productId => ({
    type: ActionTypes.DELETE_PRODUCT,
    payload: { productId }
});

const DISPATCH_LOAD_PRODUCTS = products => ({
    type: ActionTypes.LOAD_PRODUCTS,
    payload: { products }
});


/***** Actions *****/

export const loadProducts = () => async (dispatch, getState) => {
    try {
        return ProductService.getSortedProducts()
            .then(tempProducts => {
                // console.log("Got Products in ProductsAction : ", tempProducts);
                dispatch(DISPATCH_LOAD_PRODUCTS(tempProducts));
                return Promise.resolve(tempProducts);
            })
            .catch(error => {
                console.error('There was an error!', error);
                return Promise.reject(error);
            });
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const getAllProducts = () => async (dispatch, getState) => {
    try {
        return ProductService.getSortedProducts()
            .then(tempProducts => {
                // console.log("Got Products in ProductsAction : ", tempProducts);
                return Promise.resolve(tempProducts);
            })
            .catch(error => {
                console.error('There was an error!', error);
                return Promise.reject(error);
            });
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const deleteProduct = productId => async (dispatch, getState) => {
    try {
        return ProductService.deleteProduct(productId)
            .then(() => {
                // console.log("Product deleted with id : ", productId);
                dispatch(DISPATCH_DELETE_PRODUCT(productId));
                return Promise.resolve(productId);
            })
            .catch(error => {
                console.error('There was an error!', error);
                return Promise.reject(error);
            });
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const addProduct = product => (dispatch, getState) => {
    try {
        return ProductService.addProduct(product)
            .then((addedProduct) => {
                // console.log("Product added with id : ", addedProduct.id);
                dispatch(DISPATCH_ADD_PRODUCT({...product, id : addedProduct.id }));
                return Promise.resolve({...product, id : addedProduct.id });
            })
            .catch(error => {
                console.error('There was an error!', error);
                return Promise.reject(error);
            });
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export const updateProduct = (id, product) => async (dispatch, getState) => {
    try {
        return ProductService.updateProduct(id, product)
            .then(() => {
                // console.log("Product updated with id : ", id);
                dispatch(DISPATCH_UPDATE_PRODUCT({...product, id : id }));
                return Promise.resolve({...product, id : id });
            })
            .catch(error => {
                console.error('There was an error!', error);
                return Promise.reject(error);
            });
    }
    catch (err) {
        return Promise.reject(err);
    }
}

