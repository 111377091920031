import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, Container, Stack } from "@mui/material";
import { useAuth } from '../hooks/useAuth';

export default function LoginModal(props) {
  const auth = useAuth();
  const [open, setOpen] = React.useState(false);

  const [values, setValues] = React.useState({
    password: '',
    email: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = () => {
    auth.signin(values.email, values.password)
    .then((user) => {
      // Signed in 
      setOpen(false);
      props.showSnackMessage({
        vertical: 'top',
        horizontal: 'center',
        snackMessage: 'Login successful for user ' + user.email,
        severity: 'success'
      });
    })
    .catch((error) => {
      // const errorCode = error.code;
      const errorMessage = error.message;
      props.showSnackMessage({
        vertical: 'top',
        horizontal: 'center',
        snackMessage: 'Login failed for user ' + values.email + ' Error : ' + errorMessage,
        severity: 'error'
      });
    });
  };

  const getLoginForm = () => {
    return (
      <Container maxWidth="sm">
        <Typography variant="h5" display="block" gutterBottom>
          {'Sign in'}
        </Typography>

        <Stack>
          <FormControl sx={{ mb: 2 }} variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              value={values.email}
              onChange={handleChange('email')}
              // endAdornment={<InputAdornment position="end">email</InputAdornment>}
              aria-describedby="outlined-email-helper-text"
              label="Email"
              inputProps={{
                'aria-label': 'email',
              }}
            />
          </FormControl>

          <FormControl sx={{ mb: 2 }} variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ my: 2 }} onClick={() => handleLogin()}>
            Submit
          </Button>
        </Stack>
      </Container>
    );
  }

  return (
    <div>
      <MenuItem key={'login'} onClick={handleClickOpen} sx={{ display: !auth.user ? 'flex' : 'none' }}>
        <Typography textAlign="center">{'Login'}</Typography>
      </MenuItem>
      <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
        <DialogTitle>Administrator Login</DialogTitle>
        <DialogContent>
          {getLoginForm()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
