import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Box, Container } from "@mui/material";

const financialInformation = () => {
  return (
    <Box>
      <Typography variant="h6" display="block" sx={{ mb: 2, ml:20 }} gutterBottom>{'Financial Details'}</Typography>
      <TableContainer sx={{ width: "600px", mx: 'auto' }} component={Paper}>
        <Table sx={{ width: "600px" }} size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">GST Number</TableCell>
              <TableCell align="left">27AHEPW8271E1ZY</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">Bank</TableCell>
              <TableCell align="left">State Bank of India</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">Account Type</TableCell>
              <TableCell align="left">Current Account</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">IFSC Code</TableCell>
              <TableCell align="left">SBIN0013527</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">Account Name</TableCell>
              <TableCell align="left">Nio Equipment And Engineering</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">Account Number</TableCell>
              <TableCell align="left">40673760853</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">Payment Modes</TableCell>
              <TableCell align="left">Credit Card, Cash, Cheque, NEFT, Google Pay, Phone Pe</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const FinancialDetails = () => {


  return (
    <Container maxWidth="md" disableGutters>
      {financialInformation()}
    </Container>
  );
};

export default FinancialDetails;