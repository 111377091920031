import React, { useState, useEffect } from "react";
import UploadImages from "./UploadImages";
import { Box, Button, Container, TextField, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import Avatar from '@mui/material/Avatar';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { getStorage, ref } from "firebase/storage";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ProductsAction from "./../actions/ProductsAction"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import ListSubheader from '@mui/material/ListSubheader';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../containers/TabPanel'
import SpecificationsTable from "containers/SpecificationsTable";

const AddProduct = (props) => {
  const isAddMode = !props.location.state?.id;
  const storage = getStorage();
  const [id, setId] = useState(undefined);
  const [uploadState, setUploadState] = useState({ progress: 0 });
  const history = useHistory();
  const [category, setCategory] = useState('');
  const [features, setFeatures] = useState([]);
  const [specifications, setSpecifications] = useState([]);
  const [customizations, setCustomizations] = useState([]);
  const [isEditFeatureMode, setIsEditFeatureMode] = useState(false);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null);
  const [isEditCustomizationMode, setIsEditCustomizationMode] = useState(false);
  const [selectedCustomizationIndex, setSelectedCustomizationIndex] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  useEffect(() => {
    if (!isAddMode) {
      setId(props.location.state.id);
      setValue('name', props.location.state.name);
      setValue('category', props.location.state.category);
      setCategory(props.location.state.category);
      setValue('price', props.location.state.price);
      setValue('description', props.location.state.description);
      setValue('sortOrder', props.location.state.sortOrder);
      setValue('productImageURL', props.location.state.productImageURL);
      if (props.location.state.features) {
        setFeatures(props.location.state.features);
      }

      if (props.location.state.specifications) {
        setSpecifications(props.location.state.specifications);
      }

      if (props.location.state.customizations) {
        setCustomizations(props.location.state.customizations);
      }
      const fileReference = ref(storage, props.location.state.productImageURL);
      let fileName = fileReference.name;
      let currentFile = { name: fileName }
      setUploadState({ 'currentFile': currentFile, 'previewImage': props.location.state.productImageURL, 'downloadURL': props.location.state.productImageURL });
    }
  }, [isAddMode, props.location.state, setValue, storage]);


  const showSelectedImage = () => {
    return (
      <Stack sx={{ mt: 1, width: 120 }}>
        <Avatar
          alt="Product Image"
          src={uploadState && uploadState.previewImage ? uploadState.previewImage : null}
          sx={{ width: 120, height: 120 }}
        >
          <CloudUploadOutlinedIcon sx={{ fontSize: 80 }} />
        </Avatar>
        <Typography sx={{ textAlign: 'center' }} variant="caption" display="block" gutterBottom>
          {uploadState && uploadState.currentFile ? uploadState.currentFile.name : 'No file selected'}
        </Typography>
      </Stack>
    );
  };

  const setUploadStateFromClient = uploadStateFromClient => {
    setUploadState({
      ...uploadState,
      currentFile: uploadStateFromClient.currentFile,
      previewImage: uploadStateFromClient.previewImage,
      downloadURL: uploadStateFromClient.downloadURL
    });
  }

  const deleteFeature = (feature, index) => {
    console.log('deleteFeature');
    let tempFeatures = [...features];
    tempFeatures = tempFeatures.filter(tempFeature => tempFeature !== feature);
    setFeatures(tempFeatures);
    setSelectedFeatureIndex(null);
  }

  const editFeature = (feature, index) => {
    console.log('editFeature');
    setIsEditFeatureMode(true);
    setSelectedFeatureIndex(index);
  }

  const saveFeature = (feature, index) => {
    console.log('saveFeature');
    setSelectedFeatureIndex(null);
  }

  const onFeatureChange = (event, index) => {
    console.log('onFeatureChange');
    let updatedValue = event.target.value;
    let tempFeatures = [...features];
    tempFeatures[index] = updatedValue;
    setFeatures(tempFeatures);
  }

  const addFeature = () => {
    console.log('addFeature');
    let tempFeatures = [...features];
    tempFeatures.push('');
    setFeatures(tempFeatures);
    setIsEditFeatureMode(true);
    setSelectedFeatureIndex(tempFeatures.length - 1);
  }

  //start
  const deleteCustomization = (customization, index) => {
    console.log('deleteCustomization');
    let tempCustomizations = [...customizations];
    tempCustomizations = tempCustomizations.filter(tempCustomization => tempCustomization !== customization);
    setCustomizations(tempCustomizations);
    setSelectedCustomizationIndex(null);
  }

  const editCustomization = (customization, index) => {
    console.log('editCustomization');
    setIsEditCustomizationMode(true);
    setSelectedCustomizationIndex(index);
  }

  const saveCustomization = (customization, index) => {
    console.log('saveCustomization');
    setSelectedCustomizationIndex(null);
  }

  const onCustomizationChange = (event, index) => {
    console.log('onCustomizationChange');
    let updatedValue = event.target.value;
    let tempCustomizations = [...customizations];
    tempCustomizations[index] = updatedValue;
    setCustomizations(tempCustomizations);
  }

  const addCustomization = () => {
    console.log('addCustomization');
    let tempCustomizations = [...customizations];
    tempCustomizations.push('');
    setCustomizations(tempCustomizations);
    setIsEditCustomizationMode(true);
    setSelectedCustomizationIndex(tempCustomizations.length - 1);
  }

  //end

  const onSpecificationsUpdate = (updatedSpecifications) => {
    console.log('onSpecificationsUpdate');
    if(updatedSpecifications && updatedSpecifications.length > 0){
      setSpecifications(updatedSpecifications);
    }
  }
  
  const onSubmit = async (formData) => {
    if(uploadState.currentFile){
      formData.productOfflineImage = formData.name +  '.' +uploadState.currentFile.name.split('.')[1];
    }else{
      formData.productOfflineImage = '';
    }
    
    formData.productImageURL = uploadState.downloadURL ? uploadState.downloadURL : '';
    if (features && features.length > 0) {
      formData.features = features;
    }

    if (specifications && specifications.length > 0) {
      formData.specifications = specifications;
    }

    if (customizations && customizations.length > 0) {
      formData.customizations = customizations;
    }

    if (isAddMode) {
      let addResponse = await props.addProduct(formData);
      if (addResponse.id) {
        history.push('manage-products');
      }
    } else {
      let updateResponse = await props.updateProduct(id, formData)
      if (updateResponse.id) {
        history.push('manage-products');
      }
    }
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderDetailTabs = () => {
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Features" {...a11yProps(0)} />
            <Tab label="Specifications" {...a11yProps(1)} />
            <Tab label="Customizations" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          {renderFeaturesList()}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {renderSpecifications()}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          {renderCustomizationList()}
        </TabPanel>
      </Box>
    );
  };

  const renderSpecifications = () => {
    return (
      <Container disableGutters>
        <SpecificationsTable specifications={specifications} onSpecificationsUpdate={onSpecificationsUpdate}></SpecificationsTable>
      </Container>
    );
  }

  const renderFeaturesList = () => {
    let listItems = features && features.length > 0 && features.map((feature, index) => {
      return (
        <ListItem disablePadding key={index} >
          <ListItemButton>
            <ListItemIcon>
              <StarBorderIcon color="primary" />
            </ListItemIcon>
            <Stack direction="row" spacing={1} justifyContent={'space-around'} sx={{ display: isEditFeatureMode && selectedFeatureIndex === index ? 'none' : 'flex', flexGrow: 1 }}>
              <ListItemText primary={feature} />
              <EditOutlinedIcon color="primary" onClick={() => { editFeature(feature, index) }} />
              <DeleteOutlinedIcon color="secondary" onClick={() => { deleteFeature(feature, index) }} />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent={'space-around'} alignItems={'center'} sx={{ display: isEditFeatureMode && selectedFeatureIndex === index ? 'flex' : 'none', flexGrow: 1 }}>
              <TextField
                fullWidth
                value={feature}
                label={isEditFeatureMode && selectedFeatureIndex === index && feature === '' ? 'Add your feature here...' : ''}
                autoFocus={isEditFeatureMode && selectedFeatureIndex === index && feature === '' ? true : false}
                variant="standard" sx={{}}
                onChange={(event) => { onFeatureChange(event, index) }}
              />
              <SendOutlinedIcon color="primary" onClick={() => { saveFeature(feature, index) }} />
            </Stack>
          </ListItemButton>
        </ListItem>
      )
    }, features);

    if (!listItems) {
      listItems = (
        <ListItem disablePadding sx={{ my: { xs: 3, md: 3 } }}>
          <ListItemButton>
            <ListItemIcon>
              <WarningAmberOutlinedIcon color="warning" />
            </ListItemIcon>
            <ListItemText primary={'No features have been added yet.'} />
          </ListItemButton>
        </ListItem>
      )
    }
    return (
      <List
        sx={{ width: '100%', maxWidth: 400, bgcolor: 'transparent' }}
        aria-label="features"
        subheader={
          <Stack direction="row" spacing={1} justifyContent={'start'} sx={{ ml: 2 }}>
            {/* <ListSubheader component="div" id="nested-list-subheader" sx={{ bgcolor: 'transparent' }}>
              <Typography variant="button" fontSize={16}>
                {'Features'}
              </Typography>
            </ListSubheader> */}
            <Button color="primary" startIcon={<AddCircleIcon />} onClick={() => { addFeature() }}>
              Add new feature
            </Button>
          </Stack>
        }
      >
        {listItems}
      </List>
    );
  };

  const renderCustomizationList = () => {
    let listItems = customizations && customizations.length > 0 && customizations.map((customization, index) => {
      return (
        <ListItem disablePadding key={index} >
          <ListItemButton>
            <ListItemIcon>
              <ConstructionOutlinedIcon color="primary" />
            </ListItemIcon>
            <Stack direction="row" spacing={1} justifyContent={'space-around'} sx={{ display: isEditCustomizationMode && selectedCustomizationIndex === index ? 'none' : 'flex', flexGrow: 1 }}>
              <ListItemText primary={customization} />
              <EditOutlinedIcon color="primary" onClick={() => { editCustomization(customization, index) }} />
              <DeleteOutlinedIcon color="secondary" onClick={() => { deleteCustomization(customization, index) }} />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent={'space-around'} alignItems={'center'} sx={{ display: isEditCustomizationMode && selectedCustomizationIndex === index ? 'flex' : 'none', flexGrow: 1 }}>
              <TextField
                fullWidth
                value={customization}
                label={isEditCustomizationMode && selectedCustomizationIndex === index && customization === '' ? 'Add your customization here...' : ''}
                autoFocus={isEditCustomizationMode && selectedCustomizationIndex === index && customization === '' ? true : false}
                variant="standard" sx={{}}
                onChange={(event) => { onCustomizationChange(event, index) }}
              />
              <SendOutlinedIcon color="primary" onClick={() => { saveCustomization(customization, index) }} />
            </Stack>
          </ListItemButton>
        </ListItem>
      )
    }, customizations);

    if (!listItems) {
      listItems = (
        <ListItem disablePadding sx={{ my: { xs: 3, md: 3 } }}>
          <ListItemButton>
            <ListItemIcon>
              <WarningAmberOutlinedIcon color="warning" />
            </ListItemIcon>
            <ListItemText primary={'No customizations have been added yet.'} />
          </ListItemButton>
        </ListItem>
      )
    }
    return (
      <List
        sx={{ width: '100%', maxWidth: 400, bgcolor: 'transparent' }}
        aria-label="customizations"
        subheader={
          <Stack direction="row" spacing={1} justifyContent={'start'} sx={{ ml: 2 }}>
            {/* <ListSubheader component="div" id="nested-list-subheader" sx={{ bgcolor: 'transparent' }}>
              <Typography variant="button" fontSize={16}>
                {'Features'}
              </Typography>
            </ListSubheader> */}
            <Button color="primary" startIcon={<AddCircleIcon />} onClick={() => { addCustomization() }}>
              Add new customization
            </Button>
          </Stack>
        }
      >
        {listItems}
      </List>
    );
  };


  const renderProductForm = () => {
    return (
      <Container maxWidth="sm">
        <Typography variant="h5" display="block" gutterBottom>
          {'Product'}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2}>
            <TextField
              variant="outlined"
              label="Name"
              fullWidth
              autoComplete="name"
              autoFocus
              sx={{ mb: 1 }}
              {...register("name", {
                required: "Name is required field"
              })}
              error={!!errors?.name}
              helperText={errors?.name ? errors.name.message : null}
            />

            <FormControl fullWidth sx={{ mb: 1, /*minWidth: 120*/ }} error={!!errors?.category}>
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                labelId="category-label"
                id="category"
                value={category}
                label="Category"
                {...register("category", {
                  required: "Category is required field", onChange: (e) => { handleCategoryChange(e) }
                })}
              >
                {props.categories.map((categoryItem) => (
                  <MenuItem key={categoryItem.name} value={categoryItem.name}>{categoryItem.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors?.category ? errors.category.message : null}</FormHelperText>
            </FormControl>

            <TextField
              variant="outlined"
              label="Price"
              fullWidth
              autoComplete="price"
              type="number"
              sx={{ mb: 1 }}
              {...register("price", {
                required: "Price is required field",
              })}
              error={!!errors?.price}
              helperText={errors?.price ? errors.price.message : null}
            />

            <TextField
              variant="outlined"
              label="Description"
              multiline
              rows={4}
              sx={{ mb: 1 }}
              fullWidth
              autoComplete="description"
              {...register("description", {
                required: "Description is required field",
              })}
              error={!!errors?.description}
              helperText={errors?.description ? errors.description.message : null}
            />

            <TextField
              variant="outlined"
              label="Sort Order"
              fullWidth
              autoComplete="sortOrder"
              type="number"
              autoFocus
              InputProps={{
                readOnly: false,
              }}
              sx={{ mb: 1 }}
              {...register("sortOrder", {
                required: "Sort Order is required field",
                valueAsNumber: true
              })}
              error={!!errors?.sortOrder}
              helperText={errors?.sortOrder ? errors.sortOrder.message : null}
            />

          </Box>

          <Typography variant="body2" display="block" gutterBottom>
            {'Product Image'}
          </Typography>

          <Box marginY={1}>
            <Stack direction={"row"} sx={{ justifyContent: "flex-start", alignItems: 'center' }}>
              {showSelectedImage()}
              <UploadImages setUploadStateFromClient={setUploadStateFromClient} uploadType="products" />
            </Stack>
          </Box>

          {/* <Box marginY={1}>
            {getFeaturesList()}
          </Box> */}

          <Box marginY={1}>
            {renderDetailTabs()}
          </Box>

          <Box marginY={1} sx={{ mx: 'auto', my: { xs: 3, md: 5 }, display: 'flex', justifyContent: 'center' }}>
            <Button onClick={() => { history.push('/manage-products'); }} variant="outlined" >
              Cancel
            </Button>

            <Button type="submit" variant="contained" color="primary" sx={{ mx: 2 }}>
              Submit
            </Button>
          </Box>
        </form>
      </Container>
    );
  };

  return (
    <Box>
      {renderProductForm()}
    </Box>
  );
};

const mapStateToProps = state => ({
  categories: state.categories,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addProduct: ProductsAction.addProduct,
  updateProduct: ProductsAction.updateProduct,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);