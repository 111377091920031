import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Container from '@mui/material/Container';
import * as CategoriesAction from "../actions/CategoriesAction"
import * as ProductsAction from "../actions/ProductsAction"
import { Link } from 'react-router-dom'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const Sitemap = (props) => {

  const getProductDetailsLink = (product) => {
    let shortProductName = product.name.replaceAll(' ', '-').toLowerCase();
    return `/product/${shortProductName}`;
  }

  const getIndianRupeeFormattedAmount = (price) => {
    let priceNumber = Math.floor(price);
    let formattedAmount = priceNumber.toLocaleString('en-IN', {
      maximumFractionDigits: 0,
      style: 'currency',
      currency: 'INR'
    });
    return formattedAmount;
  }

  const getProductImage = (product) => {
    try {
      // Import image on demand
      let productImage;
      if (product.productOfflineImage) {
        productImage = require(`assets/images/products/${product.productOfflineImage}`);
      }

      // If the image doesn't exist. return null
      if (!productImage) return product.productImageURL;
      return productImage.default;
    } catch (error) {
      console.log(`Image with name "${product.productOfflineImage}" does not exist`);
      return product.productImageURL;
    }
  }

  const renderProductsForSelectedCategory = (selectedCategory) => {
    let allProducts = props.products;
    let productsForSelectedCategory;
    if (selectedCategory.name !== 'all') {
      productsForSelectedCategory = allProducts.filter(item => item.category === selectedCategory.name);
    }

    let productItems = productsForSelectedCategory.map((product, index) => {
      return (
        // <Link to={getProductDetailsLink(product)} style={{ width: '380px', maxWidth: '380px', color: '1976d2' }} activeStyle={{ color: '1976d2' }}>
        <Link to={getProductDetailsLink(product)} key={index} style={{ color: '1976d2', textDecoration: "none" }}>
          <Card sx={{ width: { xs: 1, md: 'auto' }, minWidth: { xs: 'auto', md: 370 }, maxWidth: { xs: 'auto', md: 380 }, display: 'flex', mx: { xs: 0, md: 2 }, my: { xs: 1, md: 2 }, boxShadow: 3 }} >
            <CardMedia
              component="img"
              sx={{ width: 151, height: 151 }}
              image={getProductImage(product)}
              alt={product.name}
            />

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <CardContent sx={{ p: '0 !important', mx: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography component="div" variant={'subtitle'} sx={{
                  width: 120,
                  fontSize: { xs: 16, md: 16 },
                  color: 'primary.main',
                  '&:hover': {
                    color: 'primary.main',
                    textDecoration: 'underline'
                  }
                }}>
                  {product.name}
                </Typography>
                <Typography variant="caption" color="text.secondary" component="div" sx={{ textDecoration: 'none' }}>
                  Apprx. Price  :
                </Typography>
                <Typography variant="body1" component="div" color='primary' sx={{ fontWeight: 'bold', textDecoration: 'none' }}>
                  {getIndianRupeeFormattedAmount(product.price)}
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Link>
      );
    });
    return productItems;
  }

  const renderSitemapPage = () => {
    return (
      <>
        <Link to={'Home'} style={{ color: '1976d2', textDecoration: "none" }}>
          <Typography
            variant="h6"
            sx={{
              mx: 2,
              py: 1,
              textTransform: 'capitalize',
              color: 'primary.main',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            {'Home Page'}
          </Typography>
        </Link>
        <Link to={'services'} style={{ color: '1976d2', textDecoration: "none" }}>
          <Typography
            variant="h6"
            sx={{
              mx: 2,
              py: 1,
              textTransform: 'capitalize',
              color: 'primary.main',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            {'Our Services'}
          </Typography>
        </Link>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
          <Link to={'about-us'} style={{ color: '1976d2', textDecoration: "none" }}>
            <Typography
              // textAlign={'center'}
              variant="h6"
              sx={{
                mx: 2,
                py: 1,
                textTransform: 'capitalize',
                color: 'primary.main',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {'About Us'}
            </Typography>
          </Link>
          <Link to={'financial-details'} style={{ color: '1976d2', textDecoration: "none" }}>
            <Typography
              // textAlign={'center'}
              variant="h6"
              sx={{
                mx: 2,
                py: 1,
                textTransform: 'capitalize',
                color: 'primary.main',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {'Financial Details'}
            </Typography>
          </Link>
          <Link to={{ pathname:'https://www.indiamart.com/nio-equipment-engineering/nio-equipment-and-engineering.pdf'}} target="_blank" style={{ color: '1976d2', textDecoration: "none" }}>
            <Typography
              variant="h6"
              sx={{
                mx: 2,
                py: 1,
                textTransform: 'capitalize',
                color: 'primary.main',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {'Brochure'}
            </Typography>
          </Link>
          <Link to={'corporate-video'} style={{ color: '1976d2', textDecoration: "none" }}>
            <Typography
              // textAlign={'center'}
              variant="h6"
              sx={{
                mx: 2,
                py: 1,
                textTransform: 'capitalize',
                color: 'primary.main',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {'Corporate Video'}
            </Typography>
          </Link>
        </Box>
        <Typography
          // textAlign={'center'}
          variant="h6"
          sx={{
            mx: 2,
            py: 1,
            textTransform: 'capitalize',
            color: 'primary.main',
          }}
        >
          {'Our Products'}
        </Typography>
        {props.categories.map((category, index) => (
          <Container key={index} disableGutters sx={{ mb: { xs: 1, md: 1 } }}>
            <Typography
              // textAlign={'center'}
              variant="h6"
              sx={{ mx: 2, py: 1, textTransform: 'capitalize' }}
              color='primary.main'>
              {category.name}
            </Typography>
            <Container disableGutters sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, mx: { xs: 0, md: 2 }, width: { xs: 1, md: 0.9 } }}>
              {renderProductsForSelectedCategory(category)}
            </Container>
          </Container>
        ))}
        <Link to={'contact-us'} style={{ color: '1976d2', textDecoration: "none" }}>
          <Typography
            // textAlign={'center'}
            variant="h6"
            sx={{
              mx: 2,
              my: 2,
              py: 1,
              textTransform: 'capitalize',
              color: 'primary.main',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            {'Contact Us'}
          </Typography>
        </Link>
      </>
    );
  };

  return (
    <Container maxWidth="lg" disableGutters>
      {renderSitemapPage()}
    </Container>
  );
};

const mapStateToProps = state => ({
  categories: state.categories,
  products: state.products,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllCategories: CategoriesAction.getAllCategories,
  getAllProducts: ProductsAction.getAllProducts,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Sitemap);