import * as React from 'react';
import Tab from '@mui/material/Tab';
import { PropTypes } from 'prop-types';
import { Box } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ImageTabItem = (props, ref) => {
  const { children, index, objectid, imageURL, name, onClick, ...other } = props;

  return (
    <>
      <Button sx={{width: { xs: 'auto', md: '140px' }}} objectid={objectid} objectname={name} onClick={(event) => { onClick(event) }} {...other}>
        <Box key={index} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar
            alt={name}
            src={imageURL}
            sx={{ display: { xs: 'none', md: 'flex' }, width: 70, height: 70, bgcolor: 'background.paper', borderRadius : 'inherit' }}
          >{imageURL ? '' : <PrecisionManufacturingOutlinedIcon sx={{ fontSize: 60 }} color='primary'  />}</Avatar>
          <Typography fontSize={16} sx={{ maxWidth: 'auto', mx: { xs: 'none', md: 'auto' }, mt: { xs: 'none', md: '5px' }, textTransform : 'capitalize' }}>
            {name}
          </Typography>
        </Box>
        {children}
      </Button>
    </>
  )
};

ImageTabItem.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  imageURL: PropTypes.string.isRequired,
  objectid : PropTypes.string.isRequired,
  objectname : PropTypes.string.isRequired
};

export default function ImageTab(props, ref) {
  const ImageTabComponent = React.forwardRef((props, ref) => {
    return <ImageTabItem  {...props} />;
  });

  return (
    <Tab
      component={ImageTabComponent}
      ref={ref}
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}