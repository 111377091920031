import * as React from 'react';
import { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import { Box, /*Stack*/ } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom'
import { deepOrange } from '@mui/material/colors';
import neeLogo from "assets/images/NEE-logo.png";
// import neeTransparentLogo from "assets/images/NEE-Transparent-Logo.png";
import appConfig from '../data/appConfig.json'
// import { StyledInputBase, Search, SearchIconWrapper } from '../containers/SearchBar';
import useStyles from "../theme/style";
import LoginModal from '../containers/LoginModal';
// import SignUpModal from '../containers/SignUpModal';
import { useHistory } from "react-router-dom";
import { useAuth } from '../hooks/useAuth';
// import SvgIcon from '@mui/material/SvgIcon';
// import { ConfirmationModal } from "../containers/ConfirmationModal";
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ConstructionIcon from '@mui/icons-material/Construction';
import BusinessIcon from '@mui/icons-material/Business';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import CloseIcon from '@mui/icons-material/Close';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CategoryIcon from '@mui/icons-material/Category';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import DownloadIcon from '@mui/icons-material/Download';

const ResponsiveAppBar = (props) => {
  const auth = useAuth();
  const history = useHistory();
  const classes = useStyles();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState(false);
  const [headerClass, setHeaderClass] = React.useState(classes.header);
  const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = React.useState(null);
  const isDownloadMenuOpen = Boolean(downloadMenuAnchorEl);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [subMenuOpen, setSubMenuOpen] = React.useState(true);

  const handleSubMenuClick = () => {
    setSubMenuOpen(!subMenuOpen);
    toggleDrawer(false);
  };

  const isMobile = useMediaQuery('@media (min-width:0px) and (max-width:599.95px)');

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    if (event.target.textContent !== 'About Us' && event.target?.parentElement?.dataset?.testid !== 'ExpandLessIcon' && event.target?.parentElement?.dataset?.testid !== 'ExpandMoreIcon' && event.target.textContent !== 'Our Business Details' && event.target.textContent !== 'About UsOur Business Details') {
      setMenuOpen(open);
    }
  };

  const handleDownloadMenuClick = (event) => {
    setDownloadMenuAnchorEl(event.currentTarget);
  };
  const handleDownloadMenuClose = () => {
    setDownloadMenuAnchorEl(null);
  };

  const onWindowScroll = React.useCallback(() => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 150 ? setHeaderClass(classes.isStickyHeader + ' ' + classes.header) : setHeaderClass(classes.header);
    }
  }, [classes.isStickyHeader, classes.header]);

  useEffect(() => {
    window.addEventListener('scroll', onWindowScroll);
    return () => window.removeEventListener('scroll', onWindowScroll);
  }, [onWindowScroll]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  };

  const getMenuList = () => (
    <Box
      sx={{ width: { xs: 350, md: 350 } }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <IconButton size="large" disableRipple={true} disableFocusRipple={true} aria-label="Close Menu" sx={{ width: '100%', justifyContent: 'end' }}>
        <CloseIcon />
      </IconButton>
      <Box sx={{ mt: -3, mb: { xs: 2, md: 0 }, display: 'flex', maxHeight: { xs: 100, md: 160 }, justifyContent: 'center' }}>
        <Avatar
          alt="Nio Equipment & Engineering"
          src={neeLogo}
          sx={{ alignItems: 'center', width: { xs: 90, md: 150 }, height: { xs: 90, md: 150 } }}
        ></Avatar>
      </Box>
      <Button
        sx={{ p: 0, mb: 1, mt: -3, color: "#333333", display: 'flex', flexGrow: 1, width: '100%' }}
      >
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1, textTransform: 'capitalize', color: { xs: '#1976d2', md: '#1976d2' } }}
        >
          Nio Equipment & Engineering
        </Typography>
      </Button>
      <Typography
        variant="body2"
        noWrap
        component="div"
        sx={{ mb: 1, flexGrow: 1, textAlign: 'center', textTransform: 'capitalize', color: { xs: 'text.primary', md: 'text.primary' } }}
      >
        Manufacturer of Material Handling Equipment
      </Typography>
      <Divider />
      <List>
        <ListItem button onClick={() => { onMenuItemClick('/products') }}>
          <ListItemAvatar>
            <Avatar>
              <ShoppingCartIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={'Products'}
            secondary={isMobile ? '' : "Check out our product catalog"}
          />
        </ListItem>
        <ListItem button onClick={() => { onMenuItemClick('/services') }}>
          <ListItemAvatar>
            <Avatar>
              <ConstructionIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Offered Services'}
            secondary={isMobile ? '' : "We offer various services like AMC, Spare Parts, Installation etc"} />
        </ListItem>
        <ListItem button onClick={() => { onMenuItemClick('/contact-us') }}>
          <ListItemAvatar>
            <Avatar>
              <BusinessIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Contact Us'}
            secondary={isMobile ? '' : "Reach us on phone, email and address"} />
        </ListItem>
        <Divider />
        <ListItem button onClick={(event) => { handleSubMenuClick(); }}>
          <ListItemAvatar>
            <Avatar>
              <GolfCourseIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'About Us'}
            secondary={isMobile ? '' : "Our Business Details"} />
          {subMenuOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ pl: 4 }} button onClick={() => { onMenuItemClick('/about-us') }}>
              <ListItemAvatar>
                <Avatar>
                  <FactCheckIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={'Fact sheet'}
                secondary={isMobile ? '' : "Our business profile"} />
            </ListItem>
            <ListItem sx={{ pl: 4 }} button onClick={() => { onMenuItemClick('/financial-details') }}>
              <ListItemAvatar>
                <Avatar>
                  <AccountBalanceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={'Financial Details'}
                secondary={isMobile ? '' : "Flexible payment methods that we support"} />
            </ListItem>
            <ListItem sx={{ pl: 4 }} button onClick={() => { window.open('https://www.indiamart.com/nio-equipment-engineering/nio-equipment-and-engineering.pdf', '_blank', 'noopener,noreferrer') }}>
              <ListItemAvatar>
                <Avatar>
                  <DocumentScannerIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={'Download Brochure'}
                secondary={isMobile ? '' : "Download our product catalog"} />
            </ListItem>
            <ListItem sx={{ pl: 4 }} button onClick={() => { onMenuItemClick('/corporate-video') }}>
              <ListItemAvatar>
                <Avatar>
                  <NextWeekIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={'Corporate Presentation'}
                secondary={isMobile ? '' : "About the products & services that we offer"} />
            </ListItem>
            <ListItem sx={{ pl: 4 }} button onClick={() => { onMenuItemClick('/sitemap') }}>
              <ListItemAvatar>
                <Avatar>
                  <AccountTreeIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={'Sitemap'}
                secondary={isMobile ? '' : "Sitemap for easy navigation"} />
            </ListItem>
          </List>
        </Collapse>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => { onMenuItemClick('/manage-products') }} sx={{ display: auth.user ? 'flex' : 'none' }}>
          <ListItemAvatar>
            <Avatar>
              <AddBusinessIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Manage Products'}
            secondary={isMobile ? '' : "Admin can manage products"} />
        </ListItem>
        <ListItem button onClick={() => { onMenuItemClick('/categories') }} sx={{ display: auth.user ? 'flex' : 'none' }}>
          <ListItemAvatar>
            <Avatar>
              <CategoryIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Manage Categories'}
            secondary={isMobile ? '' : "Categorization of products"} />
        </ListItem>
        <ListItem button onClick={() => { onMenuItemClick('/download-entities') }} sx={{ display: auth.user ? 'flex' : 'none' }}>
          <ListItemAvatar>
            <Avatar>
              <DownloadIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Download Entities'}
            secondary={isMobile ? '' : "Download product, categories etc"} />
        </ListItem>
        <ListItem button onClick={() => { onMenuItemClick('/force-refresh-data') }} sx={{ display: auth.user ? 'flex' : 'none' }}>
          <ListItemAvatar>
            <Avatar>
              <DownloadIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Force Refresh Data'}
            secondary={isMobile ? '' : "Reload the data from server"} />
        </ListItem>
        
        <ListItem button onClick={() => { onMenuItemClick('/upload-file') }} sx={{ display: auth.user ? 'flex' : 'none' }}>
          <ListItemAvatar>
            <Avatar>
              <DownloadIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Upload File'}
            secondary={isMobile ? '' : "Upload product images, brochures etc"} />
        </ListItem>
        <ListItem button onClick={() => { logoutUser() }} sx={{ display: auth.user ? 'flex' : 'none' }}>
          <ListItemAvatar>
            <Avatar>
              <LogoutIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Logout'} />
        </ListItem>
        <ListItem button onClick={() => { onMenuItemClick('/login') }} sx={{ display: auth.user ? 'none' : 'flex' }}>
          <ListItemAvatar>
            <Avatar>
              <LoginIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Sign In'} />
        </ListItem>

      </List>
    </Box >
  );

  const logoutUser = () => {
    handleCloseUserMenu();
    auth.signout()
      .then(() => {
        // Sign-out successful.
        props.showSnackMessage({
          vertical: 'top',
          horizontal: 'center',
          snackMessage: 'Sign-out successful.',
          severity: 'success'
        });
      })
      .catch((error) => {
        // An error happened.
        // const errorCode = error.code;
        const errorMessage = error.message;
        props.showSnackMessage({
          vertical: 'top',
          horizontal: 'center',
          snackMessage: 'Logout failed, Error : ' + errorMessage,
          severity: 'error'
        });
      });

    /*
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      props.showSnackMessage({
        vertical: 'top',
        horizontal: 'center',
        snackMessage: 'Sign-out successful.',
        severity: 'success'
      });
    }).catch((error) => {
      // An error happened.
    });
    */
  };

  const getModalButtons = () => {
    return (
      <React.Fragment>
        <Button variant="contained" color="primary" onClick={closeConfirmationModal}>Close</Button>
      </React.Fragment>
    )
  }

  const openConfirmationModal = (product) => {
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const onUserMenuClick = (path) => {
    console.log('onUserMenuClick path : ', path);
    history.push(path);
    handleCloseUserMenu();
  }

  const onMenuItemClick = (path) => {
    console.log('onMenuItemClick path : ', path);
    history.push(path);
    toggleDrawer(false);
  }

  // const loadTrustSeal = () => {
  //   return {
  //     __html: '<iframe src="/TrustSeal.html" width="600" height="750" style="border:none"></iframe>'
  //   }
  // }

  const renderAppDrawer = () => {
    return (
      <>
        <SwipeableDrawer
          anchor={'left'}
          open={menuOpen}
          onClose={() => { toggleDrawer(false) }}
          onOpen={() => { toggleDrawer(true) }}
        >
          {getMenuList()}
        </SwipeableDrawer>
      </>
    );
  }

  return (
    <AppBar className={headerClass}>
      <Container maxWidth="xl" sx={{ flexGrow: 1, boxShadow: { xs: 3, md: 0 } }}>
        <Toolbar className={classes.headerToolbar} disableGutters >
          {/* Menu */}
          <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
            {renderAppDrawer()}
            <IconButton
              size="large"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon sx={{ color: { xs: '#fff', md: '#1976d2' } }} />
            </IconButton>
          </Box>
          {/* Menu Ends*/}

          {/* Logo */}
          <Box sx={{ display: { xs: 'none', md: 'flex', maxHeight: 40 } }}>
            <Link to="/" >
              <img src={neeLogo} alt="Nio Equipment & Engineering" className='neeLogo' />
            </Link>
          </Box>

          {/* Brand Name */}
          <Button
            sx={{ color: "#333333", display: 'flex', flexGrow: { xs: 1, md: 0 } }}
            onClick={() => { history.push('/') }}
          >
            <Typography
              variant="h6"
              // color='primary.main'
              noWrap
              component="div"
              sx={{ flexGrow: 1, textTransform: 'capitalize', color: { xs: '#fff', md: '#1976d2' } }}
            >
              Nio Equipment & Engineering
            </Typography>
          </Button>
          {/* <Button sx={{ display: { xs: 'none', md: 'flex' } }} className={classes.trustSealButton} onClick={() => { openConfirmationModal() }}>
            <Stack direction={'row'} sx={{ mt: 1 }}>
              <SvgIcon >
                <g data-name="Group 1336" transform="translate(-4369.391 -5904.945)">
                  <circle cx="8.271" cy="8.271" r="8.271" fill="#efc732" data-name="Ellipse 81" transform="translate(4369.391 5904.945)"></circle>
                  <path d="M0 0h16.542v2.789H0z" fill="#efc732" data-name="Rectangle 624" transform="translate(4369.391 5919.429)"></path>
                  <path d="M4380.563 5908.823l-1.076 1.076-4.009 4.011-2.033-2.032-.276-.278-.545.547-.544.545 3.4 3.4 6.176-6.177zm0 0" fill="#d02627" data-name="Path 939" transform="translate(.796 1.148)"></path>
                </g>
              </SvgIcon>
              <Typography
                variant="caption"
                color='primary.main'
                noWrap
                component="div"
                sx={{ flexGrow: 1, mr: 2, textTransform: 'capitalize' }}
              >
                TrustSEAL Verified
              </Typography>
            </Stack>
          </Button> */}

          {/* Admin Navigation Menu */}
          <Box sx={{ display: auth.user ? 'none' : 'none' }}>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {appConfig.adminPages.map((page, index) => (
                <Button
                  key={page}
                  component={Link}
                  to={appConfig.adminPagesRoutes[index]}
                  sx={{ color: "#333333", display: 'block' }}
                >
                  {page}
                </Button>
              ))}
            </Box>
          </Box>

          {/* User Navigation Menu */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: '1' }}>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                component={Link}
                to={'/manage-products'}
                sx={{ display: auth.user ? 'flex' : 'none', color: "#333333" }}
              >
                Products
              </Button>
              <Button
                component={Link}
                to={'/services'}
                sx={{ color: "#333333", display: 'block' }}
              >
                Our Services
              </Button>
              {/* <Button
                component={Link}
                to={'inquiry'}
                sx={{ color: "#333333", display: 'block' }}
              >
                Inquiry
              </Button> */}
              <Button
                component={Link}
                to={'/contact-us'}
                sx={{ color: "#333333", display: 'block' }}
              >
                Contact Us
              </Button>

              <Button
                id="basic-button"
                aria-controls={isDownloadMenuOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isDownloadMenuOpen ? 'true' : undefined}
                sx={{ color: "#333333" }}
                onClick={(event) => { handleDownloadMenuClick(event) }}
                endIcon={<ArrowDropDownCircleOutlinedIcon />}
              >
                About Us
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={downloadMenuAnchorEl}
                open={isDownloadMenuOpen}
                onClose={() => { handleDownloadMenuClose() }}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem component={Link} to={'/about-us'} onClick={() => { handleDownloadMenuClose() }}>Our Factsheet</MenuItem>
                <MenuItem onClick={() => { handleDownloadMenuClose(); history.push('/financial-details'); }}>Financial Details</MenuItem>
                <MenuItem onClick={() => { handleDownloadMenuClose(); window.open('https://www.indiamart.com/nio-equipment-engineering/nio-equipment-and-engineering.pdf', '_blank', 'noopener,noreferrer') }}>Brochure</MenuItem>
                <MenuItem onClick={() => { handleDownloadMenuClose(); history.push('/corporate-video'); }}>Corporate Video</MenuItem>
                <MenuItem onClick={() => { handleDownloadMenuClose(); history.push('/sitemap'); }}>Sitemap</MenuItem>
              </Menu>
            </Box>
          </Box>

          {/* Search Bar */}
          {/* <Search sx={{ display: { xs: 'none', md: 'flex' } }}>
            <SearchIconWrapper>
              <SearchIcon sx={{ color: '#ff5722' }} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search Products…"
              inputProps={{ 'aria-label': 'search' }}
              sx={{ color: '#333' }}
            />
          </Search> */}

          {/* User Settings */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 0 }} className={classes.userSettings} >
            {/* <Tooltip title="User Menu"> */}
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0 }}
              aria-controls={Boolean(anchorElUser) ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorElUser) ? 'true' : undefined}>
              <Avatar alt="Nio Equipment & Engineering" sx={{ bgcolor: deepOrange[500] }} src="/static/images/avatar/2.jpg" >
              </Avatar>
            </IconButton>
            <Menu
              sx={{ mt: 8 }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={() => { onUserMenuClick('/about-us') }}  >
                <Typography textAlign="center">{'About Us'}</Typography>
              </MenuItem>
              <MenuItem onClick={() => { onUserMenuClick('/contact-us') }} >
                <Typography textAlign="center">{'Contact Us'}</Typography>
              </MenuItem>
              <MenuItem onClick={() => { onUserMenuClick('/products') }}  >
                <Typography textAlign="center">{'Products'}</Typography>
              </MenuItem>
              <MenuItem onClick={() => { onUserMenuClick('/categories') }}  >
                <Typography textAlign="center">{'Categories'}</Typography>
              </MenuItem>
              <MenuItem onClick={() => { onUserMenuClick('/add-product') }}  >
                <Typography textAlign="center">{'Add Product'}</Typography>
              </MenuItem>
              <MenuItem onClick={() => { onUserMenuClick('/add-category') }}  >
                <Typography textAlign="center">{'Add Category'}</Typography>
              </MenuItem>
              <LoginModal showSnackMessage={props.showSnackMessage} ></LoginModal>
              {/* <SignUpModal showSnackMessage={props.showSnackMessage} ></SignUpModal> */}
              <MenuItem onClick={logoutUser} sx={{ display: auth.user ? 'flex' : 'none' }}>
                <Typography textAlign="center">{'Logout'}</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      {/* <ConfirmationModal
        isDense={true}
        fullWidth={false}
        maxWidth={'610px'}
        isOpen={isConfirmationModalOpen}
        handleClose={() => { closeConfirmationModal(); }}
        customButtons={getModalButtons()}
      >
        <div sx={{ width: "600" }} dangerouslySetInnerHTML={loadTrustSeal()} />
      </ConfirmationModal> */}
    </AppBar>
  );
};
export default ResponsiveAppBar;
