import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Select } from "@mui/material";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Stack } from "@mui/material";
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { TextField } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useHistory } from "react-router-dom";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, listAll } from "firebase/storage";
import { LinearProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';

const UploadFile = (props) => {
  const history = useHistory();
  
  const [uploadType, setUploadType] = useState('images');
  const [acceptableUploadFileTypes, setAcceptableUploadFileTypes] = useState('image/*');
  const [folder, setFolder] = useState('select');
  const [customFolder, setCustomFolder] = useState();
  const [uploadState, setUploadState] = useState({ progress: 0 });
  const [showPostUploadView, setShowPostUploadView] = useState(false);

  const [currentFile, setCurrentFile] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [imageInfos, setImageInfos] = useState([]);
  const [uploadButtonText, setUploadButtonText] = useState('Upload');
  const [availableFolders, setAvailableFolders] = useState([]);

  const updateAcceptedUploadFileTypes = React.useCallback(() => {
    let fileType = '';
    if(uploadType === 'images'){
      fileType = 'image/*';
    } 
    if(uploadType === 'documents'){
      fileType = 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } 
    if(uploadType === 'videos'){
      fileType = 'video/*';
    } 
    setAcceptableUploadFileTypes(fileType);
  }, [uploadType,setAcceptableUploadFileTypes]);

  useEffect(() => {
    const storage = getStorage();

    // Create a reference under which you want to list
    const listRef = ref(storage, uploadType);

    const availableFolderList = [];
    // Find all the prefixes and items.
    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
          availableFolderList.push(folderRef.fullPath);
          // console.log('found folder fullPath: '+folderRef.fullPath);
          // console.log('found folder name: '+folderRef.name);
          // console.log('found folder bucket: '+folderRef.bucket);
        });
        setAvailableFolders(availableFolderList);
        setFolder('select');
        res.items.forEach((itemRef) => {
          // All the items under listRef.
          // console.log('found file fullPath: '+itemRef.fullPath);
          // if(itemRef.fullPath.indexOf('.')>0){
          //   console.log('Not a directory, skipping');
          // }
        });
      }).catch((error) => {
        // Uh-oh, an error occurred!
      });

      updateAcceptedUploadFileTypes();
  }, [uploadType,updateAcceptedUploadFileTypes]);

  const onTargetFolderChange = (event) => {
    console.log('onTargetFolderChange');
    setFolder(event.target.value);
  }

  const onCustomFolderChange = (event) => {
    console.log('onCustomFolderChange');
    setCustomFolder(event.target.value);
  }

 


  const showSelectedImage = () => {
    return (
      <Stack sx={{ mt: 1, width: 120, display : uploadType === 'images' ? 'flex' : 'none' }}>
        <Avatar
          alt="Image"
          src={uploadState && uploadState.previewImage ? uploadState.previewImage : null}
          sx={{ width: 120, height: 120 }}
        >
          <CloudUploadOutlinedIcon sx={{ fontSize: 80 }} />
        </Avatar>
      </Stack>
    );
  };

  const showUploadPage = () => {
    setShowPostUploadView(false);
    setFolder('select');
    setUploadType('images');
    setAcceptableUploadFileTypes('image/*');
    setCustomFolder('');
    setUploadState({ progress: 0 });
  }

  const showUploadedImage = () => {
    return (
      <Stack sx={{ mt: 1, width: 120, display : uploadType === 'images' ? 'flex' : 'none' }}>
        <Avatar
          alt="Image"
          src={imageInfos ? imageInfos : null}
          sx={{ width: 120, height: 120 }}
        >
          <CloudUploadOutlinedIcon sx={{ fontSize: 80 }} />
        </Avatar>
        <Typography sx={{ textAlign: 'center' }} variant="caption" display="block" gutterBottom>
          {uploadState && uploadState.currentFile ? uploadState.currentFile.name : 'No file selected'}
        </Typography>
      </Stack>
    );
  };

  const setUploadStateFromClient = uploadStateFromClient => {
    setUploadState({
      ...uploadState,
      currentFile: uploadStateFromClient.currentFile,
      previewImage: uploadStateFromClient.previewImage,
      downloadURL: uploadStateFromClient.downloadURL
    });
  }

  const renderPostUploadSection = () => {
    return (
      <Box sx={{ m: 5 }}>
        <Typography variant="h4" sx={{ textTransform: 'uppercase' }} display="block" gutterBottom>
          {'Upload File'}
        </Typography>
        <Alert severity="success" sx={{ my: 3 }}>{'File was uploaded successfully !'}</Alert>
        <Typography variant="h6" sx={{ my: 1 }} display="block" gutterBottom>
          {'Selected target folder for upload :'}
        </Typography>
        <Typography variant="h6" sx={{ mb: 3 }} display="block" gutterBottom>
          {folder === 'other' ? customFolder : folder}
        </Typography>
        {showUploadedImage()}
        <Stack direction={"column"} sx={{ my: 3, justifyContent: "flex-start", alignItems: 'left' }}>
          <Typography variant="subtitle2" sx={{ my: 2 }}>{'Uploaded file is available at :'}</Typography>
          <Typography variant="subtitle2" sx={{ my: 2 }}>{imageInfos}</Typography>
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              window.open(imageInfos, '_blank', 'noopener,noreferrer')
            }}
          >
            View Online
          </Link>

        </Stack>
        <Box marginY={1} sx={{ height: '50px', mx: 'auto', my: { xs: 3, md: 5 }, display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ height: '50px' }}>
            <Button variant="outlined" sx={{ mx: '5px' }} component="span" onClick={() => { showUploadPage() }}>
              Upload Again
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  const renderUploadSection = () => {
    return (
      <Box marginY={1}>
        <Stack direction={"row"} sx={{ justifyContent: "flex-start", alignItems: 'center' }}>
          {showSelectedImage()}
          <Stack direction={"row"} sx={{ justifyContent: "flex-start", alignItems: 'center', ml: '10px' }}>
            <label htmlFor="btn-upload">
              <input
                id="btn-upload"
                name="btn-upload"
                style={{ display: "none" }}
                type="file"
                accept={acceptableUploadFileTypes}
                // accept="image/*"
                onChange={selectFile}
              />
              <Box sx={{ height: '50px' }}>
                <Button sx={{ mx: '5px' }} variant="outlined" component="span">
                  Select
                </Button>
              </Box>
            </label>
            {message && (
              <Typography
                variant="subtitle2"
                className={`upload-message ${isError ? "error" : ""}`}
              >{message}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Box>
    );
  }

  const selectFile = (event) => {
    setCurrentFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
    setProgress(0);
    setMessage('');

    setUploadStateFromClient({
      currentFile: event.target.files[0],
      previewImage: URL.createObjectURL(event.target.files[0]),
    });
  }

  const setUploadButtonProps = (text) => {
    setUploadButtonText(text);
  };

  const upload = () => {
    if(folder === 'select'){
      alert('Please select folder');
      return;
    }

    const storage = getStorage();
    const metadata = {
      // contentType: 'image/jpeg',
      contentType: currentFile.type,
      cacheControl: 'public,max-age=4000',
    };

    setProgress(0);
    setIsUploading(true);
    setUploadButtonProps('Wait...', 'primary', false);
    
    let uploadDirectory = folder === 'other' ? uploadType + '/' + customFolder : folder;
    const storageRef = ref(storage, uploadDirectory + '/' + currentFile.name);
    const uploadTask = uploadBytesResumable(storageRef, currentFile, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(progress);
        setIsUploading(true);

        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            setMessage('Upload is paused');
            setIsError(true);
            break;
          case 'running':
            // console.log('Upload is running');
            setMessage('Upload is running');
            setMessage('');
            setIsError(false);
            break;
          default:
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            setProgress(0);
            setCurrentFile(undefined);
            setMessage('User does not have permission to access the object');
            setIsError(true);
            break;
          case 'storage/canceled':
            // User canceled the upload
            setProgress(0);
            setCurrentFile(undefined);
            setMessage('User canceled the upload');
            setIsError(true);
            break;
          default:
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            setProgress(0);
            setCurrentFile(undefined);
            setMessage('Unknown error occurred');
            setIsError(true);
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          setMessage('');
          setIsError(false);
          setIsUploading(false);
          setImageInfos(downloadURL);

          setTimeout(() => {
            setProgress(0);
          }, 2000);

          setUploadStateFromClient({
            currentFile: currentFile,
            previewImage: previewImage,
            downloadURL: downloadURL
          });

          setUploadButtonProps('Upload');
          setShowPostUploadView(true);
        });
      }
    );
  }

  const renderUploadPage = () => {
    return (
      <Box sx={{ m: 5 }}>
        <Typography variant="h4" sx={{ textTransform: 'uppercase' }} display="block" gutterBottom>
          {'Upload File'}
        </Typography>
        <FormControl fullWidth sx={{ my: 3 }}>
          <InputLabel id="uploadTypeLabel">Select the upload type</InputLabel>
          <Select
            labelId="uploadTypeLabel"
            id="uploadType"
            value={uploadType}
            onChange={(event) => { setUploadType(event.target.value) }}
            label="Select the Upload type"
          >
            <MenuItem key="images" value='images'>{'Images'}</MenuItem>
            <MenuItem key="documents" value='documents'>{'Documents'}</MenuItem>
            <MenuItem key="videos" value='videos'>{'Videos'}</MenuItem>
          </Select>
          <FormHelperText>Your file will be uploaded in this root folder.</FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ my: 3 }}>
          <InputLabel id="folder-label">Select the target folder</InputLabel>
          <Select
            labelId="folder-label"
            id="folder"
            value={folder}
            onChange={(event) => { onTargetFolderChange(event) }}
            label="Select the target folder"
          >
            <MenuItem key="select" value='select'>{'Select the target folder'}</MenuItem>
            {availableFolders && availableFolders.length > 0 && availableFolders.map((availableFolder) => (
              <MenuItem key={availableFolder} value={availableFolder}>{availableFolder}</MenuItem>
            ))}
            {/* <MenuItem key="productImages" value='images/products'>{'images/products'}</MenuItem>
            <MenuItem key="categoryImages" value='images/categories'>{'images/categories'}</MenuItem> */}
            <MenuItem key="other" value='other'>{'Other'}</MenuItem>
          </Select>
          <FormHelperText>Your uploaded file will be stored in this folder.</FormHelperText>
        </FormControl>
        <TextField
          variant="outlined"
          label="Custom Folder Name"
          fullWidth
          autoComplete="Folder"
          value={customFolder}
          onChange={(event) => { onCustomFolderChange(event) }}
          sx={{ mb: 1, display: folder === 'other' ? 'block' : 'none' }}
          helperText={'Type the custom folder name here'}
        />
        {renderUploadSection()}
        <Box marginY={1} sx={{ height: '50px', mx: 'auto', my: { xs: 3, md: 5 }, display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ height: '50px' }}>
            <Button variant="outlined" sx={{ mx: '5px' }} component="span" onClick={() => { history.push('/home'); }}>
              Back to Home
            </Button>
          </Box>

          {progress === 100 ? (
            <Box color="success.main" sx={{ height: '50px', }} display="flex">
              <CheckIcon color="success" />
              <Typography>Success</Typography>
            </Box>
          ) : (
            <>
              <Box sx={{ height: '50px' }}>
                <Button
                  // color={uploadButtonTextColor}
                  variant="contained"
                  component="span"
                  sx={{ marginLeft: '5px' }}
                  disabled={!currentFile}
                  onClick={upload}
                >
                  {uploadButtonText}
                </Button>
                {isUploading ? (
                  <LinearProgress sx={{ mt: '5px', ml: '5px' }} variant="determinate" value={progress} />
                ) : null}
              </Box>
            </>
          )}

        </Box>
      </Box>
    );
  };

  return (
    <Container maxWidth="sm">
      {!showPostUploadView ? renderUploadPage() : undefined}
      {showPostUploadView ? renderPostUploadSection() : undefined}
    </Container>
  );
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);