import React, { useEffect, useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import { PropTypes } from 'prop-types';
import Container from '@mui/material/Container';
/*
https://kareemaly.github.io/react-items-carousel/
https://github.com/kareemaly/react-items-carousel
*/


export const ReactCarouselItem = ({
  carouselItems,
  numberOfCardsToShow,
  chevronWidth,
  enableAutoplay,
  autoPlayDelay,
  slidesToScroll,
  outsideChevron,
  gutter,
  showSlither,
  firstAndLastGutter,
  infiniteLoop,
  disableSwipe,
  classes,
  hideChevrons,
}) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const autoSlideToNextItem = React.useCallback(() => {
    // let updateIndex = activeItemIndex + 1 < noOfItems ? activeItemIndex + 1 : 1;
    let noOfItems = carouselItems && carouselItems.length ? carouselItems.length : 0;
    let updateIndex = activeItemIndex + 1 < noOfItems ? activeItemIndex + (slidesToScroll) : 1;
    setActiveItemIndex(updateIndex);
  }, [activeItemIndex, carouselItems, slidesToScroll]);

  useEffect(() => {
    // Anything in here is fired on component mount.
    let interval;
    let noOfItems = carouselItems && carouselItems.length ? carouselItems.length : 0;
    if(enableAutoplay && (noOfItems > numberOfCardsToShow)){
      interval = setInterval(autoSlideToNextItem, autoPlayDelay);
    }
    return () => {
      // Anything in here is fired on component unmount.
      if(enableAutoplay){
        clearInterval(interval);
      }
    }
  }, [autoSlideToNextItem, enableAutoplay, autoPlayDelay, carouselItems, numberOfCardsToShow]);

  const onChange = value => {
    setActiveItemIndex(value);
  }

  return (
    <>
      {/* <div> */}
      <Container disableGutters sx={{ px : '10px',}}>
        <ItemsCarousel
          infiniteLoop={infiniteLoop}
          gutter={gutter}
          activePosition={'center'}
          disableSwipe={disableSwipe}
          numberOfCards={numberOfCardsToShow}
          slidesToScroll={slidesToScroll}
          showSlither={showSlither}
          firstAndLastGutter={firstAndLastGutter}
          activeItemIndex={activeItemIndex}
          requestToChangeActive={onChange}
          alwaysShowChevrons={false}
          rightChevron={hideChevrons ? undefined : <IconButton size="large"><KeyboardArrowRight fontSize="large" /></IconButton>}
          leftChevron={hideChevrons ? undefined : <IconButton size="large"><KeyboardArrowLeft fontSize="large" /></IconButton>}
          chevronWidth={chevronWidth}
          outsideChevron={outsideChevron}
          children={carouselItems}
          classes={classes}
          // enablePlaceholder={true}
          // numberOfPlaceholderItems={slidesToScroll}
        >
          {/* {carouselItems} */}
        </ItemsCarousel>
        </Container>
      {/* </div> */}
    </>
  )
}

ReactCarouselItem.propTypes = {
  carouselItems: PropTypes.array.isRequired,
  chevronWidth: PropTypes.number,
  numberOfCardsToShow: PropTypes.number,
  enableAutoplay: PropTypes.bool,
  autoPlayDelay: PropTypes.number,
  slidesToScroll: PropTypes.number,
  outsideChevron: PropTypes.bool,
  gutter: PropTypes.number,
  showSlither: PropTypes.bool,
  firstAndLastGutter: PropTypes.bool,
  infiniteLoop: PropTypes.bool,
  disableSwipe: PropTypes.bool,
  classes: PropTypes.shape({
    wrapper: PropTypes.string,
    itemsWrapper: PropTypes.string,
    itemsInnerWrapper: PropTypes.string,
    itemWrapper: PropTypes.string,
    rightChevronWrapper: PropTypes.string,
    leftChevronWrapper: PropTypes.string,
  }),
  hideChevrons: PropTypes.bool,
}

ReactCarouselItem.defaultProps = {
  chevronWidth: 80,
  enableAutoplay: false,
  autoPlayDelay: 2000,
  numberOfCardsToShow: 3,
  slidesToScroll: 3,
  outsideChevron: true,
  gutter: 12,
  showSlither: false,
  firstAndLastGutter: true,
  infiniteLoop: false,
  disableSwipe: false,
  classes: {
    wrapper: 'react-carousel',
    itemsWrapper: 'root-items-wrapper',
    itemsInnerWrapper: 'items-inner-wrapper',
    itemWrapper: 'item-wrapper',
    rightChevronWrapper: 'right-chevron-wrapper',
    leftChevronWrapper: 'left-chevron-wrapper'
  },
  hideChevrons: false,
};