import { Stack, Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Button from '@mui/material/Button';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import useStyles from "../theme/style";
import Link from '@mui/material/Link';
import { useHistory } from "react-router-dom";

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box sx={{ backgroundColor: '#424040 !important' }}>
      <Box className={classes.footer}>
        {/* <Stack >
        <LocationOnOutlinedIcon sx={{ fontSize: 60, mt: { xs: 0, md: 8 }, display: { xs: 'flex', md: 'flex' } }} />
      </Stack> */}
        <Stack sx={{ my: { xs: 3, md: 6 } }}>
          <Typography variant="h5" sx={{ my: { xs: 1, md: 3 } }}>
            Locate Us
          </Typography>
          <Typography variant="body2" sx={{ my: 1 }}>
            Nio Equipment & Engineering
          </Typography>
          <Typography variant="body2" sx={{ my: 1 }}>
            J-318, MIDC, Bhosari
          </Typography>
          <Typography  variant="body2" sx={{ my: 1 }}>
            Maharashtra, Pune - 411026
          </Typography>
          <Button variant="contained" startIcon={<LocationOnOutlinedIcon />} onClick={() => {window.open('https://www.google.com/maps/place/Nio+Equipment+And+Engineering/@18.6351588,73.8281625,17z/data=!3m1!4b1!4m5!3m4!1s0x3bc2b9c9970f0b99:0x41a12f1e262aeb85!8m2!3d18.6351537!4d73.8303512', '_blank', 'noopener,noreferrer') }} sx={{ my: { xs: 2, md: 2 } }}>
            Get Directions
          </Button>
        </Stack>

        {/* <Stack>
        <ShoppingCartOutlinedIcon sx={{ fontSize: 60, mt: 8 }} />
      </Stack> */}
        <Stack sx={{ my: { xs: 3, md: 6 } }}>
          <Typography variant="h5" sx={{ my: { xs: 1, md: 3 } }}>
            Popular Products
          </Typography>
          <Typography variant="body2" sx={{ my: 1 }}>
            Hydraulic Electric Scissor Lift
          </Typography>
          <Typography variant="body2" sx={{ my: 1 }}>
            Goods Lift
          </Typography>
          <Typography variant="body2" sx={{ my: 1 }}>
            Mobile Dock Ramp
          </Typography>
          <Button variant="contained" startIcon={<ShoppingCartOutlinedIcon />} onClick={() => {history.push('products'); }} sx={{ my: { xs: 2, md: 2 } }}>
            View All Products
          </Button>
        </Stack>

        {/* <Stack>
        <PhoneIcon sx={{ fontSize: 60, mt: 8 }} />
      </Stack> */}
        <Stack sx={{ my: { xs: 3, md: 6 } }}>
          <Typography variant="h5" sx={{ my: { xs: 1, md: 3 } }}>
            Contact Us
          </Typography>
          <Link sx={{typography: 'body2', my: 1,  color:'inherit', textDecoration:'none'}} href="tel:+917020611480">{'+91 7020611480'}</Link>
          <Link sx={{typography: 'body2', my: 1,  color:'inherit', textDecoration:'none'}} href="tel:+919834964986">{'+91 9834964986'}</Link>
          <Link sx={{typography: 'body2', my: 1,  color:'inherit', textDecoration:'none'}} href="tel:+919822413734">{'+91 9822413734'}</Link>
          <Button variant="contained" href="tel:+917020611480" startIcon={<PhoneIcon />} sx={{ my: { xs: 2, md: 2 } }}>
            Call Us
          </Button>
        </Stack>

        {/* <Stack>
        <MailOutlineIcon sx={{ fontSize: 60, mt: 8 }} />
      </Stack> */}
        <Stack sx={{ my: { xs: 3, md: 6 } }}>
          <Typography variant="h5" sx={{ my: { xs: 1, md: 3 } }}>
            Write to Us
          </Typography>
          <Link sx={{ typography: 'body2', my: 1,  color: 'inherit', textDecoration: 'none' }} href="mailto:info@nioequipment.in">{'info@nioequipment.in'}</Link>
          <Link sx={{ typography: 'body2', my: 1,  color: 'inherit', textDecoration: 'none' }} href="mailto:sales@nioequipment.in">{'sales@nioequipment.in'}</Link>
          <Link sx={{ typography: 'body2', my: 1,  color: 'inherit', textDecoration: 'none' }} href="mailto:nio.equipment.engg@gmail.com">{'nio.equipment.engg@gmail.com'}</Link>
          <Button variant="contained" href="mailto:info@nioequipment.in" startIcon={<MailOutlineIcon />} sx={{ my: { xs: 2, md: 2 } }}>
            Inquire Now
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default Footer;