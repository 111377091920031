import React, { useState, useEffect } from "react";
import UploadImages from "./UploadImages";
import { Box, Button, Container, TextField, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import Avatar from '@mui/material/Avatar';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getStorage, ref } from "firebase/storage";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CategoriesAction from "./../actions/CategoriesAction"

const AddCategory = (props) => {
  const isAddMode = !props.location.state?.id;
  const storage = getStorage();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!isAddMode) {
      setId(props.location.state.id);
      setValue('name', props.location.state.name);
      setValue('description', props.location.state.description);
      setValue('active', props.location.state.isActive);
      setValue('sortOrder', props.location.state.sortOrder);
      setIsActive(props.location.state.isActive);
      setValue('categoryImageURL', props.location.state.categoryImageURL);

      const fileReference = ref(storage, props.location.state.categoryImageURL);
      let fileName = fileReference.name;
      let currentFile = { name: fileName }
      setUploadState({ 'currentFile': currentFile, 'previewImage': props.location.state.categoryImageURL, 'downloadURL': props.location.state.categoryImageURL });
    }
  }, [isAddMode, props.location.state, setValue, storage]);

  const [uploadState, setUploadState] = useState({ progress: 0 });
  const [isActive, setIsActive] = useState(true);
  const [id, setId] = useState(undefined);
  const history = useHistory();

  const showSelectedImage = () => {
    return (
      <Stack sx={{ mt: 1, width: 120 }}>
        <Avatar
          alt="Category Image"
          src={uploadState && uploadState.previewImage ? uploadState.previewImage : null}
          sx={{ width: 120, height: 120 }}
        >
          <CloudUploadOutlinedIcon sx={{ fontSize: 80 }} />
        </Avatar>
        <Typography sx={{ textAlign: 'center' }} variant="caption" display="block" gutterBottom>
          {uploadState && uploadState.currentFile ? uploadState.currentFile.name : 'No file selected'}
        </Typography>
      </Stack>
    );
  };

  const setUploadStateFromClient = uploadStateFromClient => {
    setUploadState({
      ...uploadState,
      currentFile: uploadStateFromClient.currentFile,
      previewImage: uploadStateFromClient.previewImage,
      downloadURL: uploadStateFromClient.downloadURL
    });
  }



  const onSubmit = async (formData) => {
    if(uploadState.currentFile){
      formData.categoryOfflineImage = formData.name +  '.' +uploadState.currentFile.name.split('.')[1];
    }else{
      formData.categoryOfflineImage = '';
    }
    
    formData.categoryImageURL = uploadState.downloadURL ? uploadState.downloadURL : '';
    formData.isActive = isActive;

    if (isAddMode) {
      let addResponse = await props.addCategory(formData);
      if(addResponse.id){
        history.push('categories');
      }
    } else {
      let updateResponse =  await props.updateCategory(id, formData)
      if(updateResponse.id){
        history.push('categories');
      }
    }
  };

  const renderCategoryForm = () => {
    return (
      <Container maxWidth="sm">
        <Typography variant="h5" display="block" gutterBottom>
          {'Category'}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={1}>
            <TextField
              variant="outlined"
              label="Name"
              fullWidth
              autoComplete="name"
              autoFocus
              InputProps={{
                readOnly: false,
              }}
              sx={{ mb: 1 }}
              {...register("name", {
                required: "Name is required field"
              })}
              error={!!errors?.name}
              helperText={errors?.name ? errors.name.message : null}
            />

            <TextField
              variant="outlined"
              label="Description"
              multiline
              rows={4}
              fullWidth
              autoComplete="description"
              sx={{ mb: 1 }}
              {...register("description", {
                required: "Description is required field",
              })}
              error={!!errors?.description}
              helperText={errors?.description ? errors.description.message : null}
            />

            <FormControlLabel
              value="activeSwitch"
              control={<Switch checked={isActive} onChange={() => { setIsActive(!isActive); }} color="secondary" />}
              label="Active"
              labelPlacement="top"
              sx={{ mx: 0, mb: 1 }}
            />

            <TextField
              variant="outlined"
              label="Sort Order"
              fullWidth
              autoComplete="sortOrder"
              type="number"
              autoFocus
              InputProps={{
                readOnly: false,
              }}
              sx={{ mb: 1 }}
              {...register("sortOrder", {
                required: "Sort Order is required field",
                valueAsNumber: true 
              })}
              error={!!errors?.sortOrder}
              helperText={errors?.sortOrder ? errors.sortOrder.message : null}
            />

          </Box>

          <Typography variant="body2" display="block" gutterBottom>
            {'Image for Category'}
          </Typography>

          <Box marginY={1}>
            <Stack direction={"row"} sx={{ justifyContent: "flex-start", alignItems: 'center' }}>
              {showSelectedImage()}
              <UploadImages setUploadStateFromClient={setUploadStateFromClient} uploadType="categories" />
            </Stack>
          </Box>

          <Box marginY={1}>
            <Button onClick={() => { history.push('categories'); }} variant="outlined" >
              Cancel
            </Button>

            <Button type="submit" variant="contained" color="primary" sx={{ mx: 2 }}>
              Submit
            </Button>
          </Box>
        </form>
      </Container>
    );
  };

  return (
    <Box>
      {renderCategoryForm()}
    </Box>
  );
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addCategory: CategoriesAction.addCategory,
  updateCategory: CategoriesAction.updateCategory,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddCategory);