import initialState from './../config/initialState';
import ActionTypes from './../actions/ActionTypes';

const productsReducer = (state = initialState.products, actionCreator) => {
    switch (actionCreator.type) {
    	case ActionTypes.LOAD_PRODUCTS:
    		return [...actionCreator.payload.products];
        case ActionTypes.ADD_PRODUCT:
            let existingProducts = [...state];
            existingProducts.push(actionCreator.payload.product);
            return existingProducts;
        case ActionTypes.UPDATE_PRODUCT:
            let existingProductsForUpdate = [...state];
            let updatedProduct = actionCreator.payload.product;
            const index = existingProductsForUpdate.findIndex((e) => e.id === updatedProduct.id);

            if (index === -1) {
                existingProductsForUpdate.push(updatedProduct);
            } else {
                existingProductsForUpdate[index] = updatedProduct;
            }
            return existingProductsForUpdate;
        case ActionTypes.DELETE_PRODUCT:
            let existingProductsForDelete = [...state];
            let productIdToBeDeleted = actionCreator.payload.productId;
            existingProductsForDelete = existingProductsForDelete.filter(item => item.id !== productIdToBeDeleted);
            return existingProductsForDelete;
        default:
            return state;
    }
}

export default productsReducer;