import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { PropTypes } from 'prop-types';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ConfirmationModal = ({
  isDense,
  fullWidth,
  maxWidth,
  isOpen,
  title,
  subtitle,
  children,
  customButtons,
  agreeButtonText,
  disagreeButtonText,
  handleClose,
  handleAgree,
  handleDisagree,
}) => {
  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="custom-dialog-title"
        aria-describedby="custom-dialog-description"
      >
        {title ? <DialogTitle id="custom-dialog-title">{title}</DialogTitle> : <></>}
        <DialogContent sx={{ m: isDense ? 0 : null, p: isDense ? 0 : null }}>
          {subtitle ? <DialogContentText id="custom-dialog-description">{subtitle}</DialogContentText> : <></>}
          {children}
        </DialogContent>
        <DialogActions>
          {/* 
            We've returned elements inside React.Fragment, This works for react rendering. 
            For rendering inside non react elements like 3rd party libraries, refer ReactItemCarousel implementation 
          */}
          {customButtons ? (
            <>
              {customButtons}
            </>
          ) : (
            <>
              <Button variant="outlined" onClick={handleDisagree}>{disagreeButtonText}</Button>
              <Button variant="contained" color="primary" onClick={handleAgree}>{agreeButtonText}</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

ConfirmationModal.propTypes = {
  isDense: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.element,
  customButtons: PropTypes.element,
  agreeButtonText: PropTypes.string,
  disagreeButtonText: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleAgree: PropTypes.func,
  handleDisagree: PropTypes.func
}

ConfirmationModal.defaultProps = {
  isDense: false,
  fullWidth: true,
  maxWidth: 'md',
  agreeButtonText: 'Agree',
  disagreeButtonText: 'Disagree'
};