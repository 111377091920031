import React, {  useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Select } from "@mui/material";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from "@mui/material";
import Container from '@mui/material/Container';
// import useDocumentTitle from 'hooks/useDocumentTitle';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import * as CategoriesAction from "../actions/CategoriesAction"
import * as ProductsAction from "../actions/ProductsAction"

const DowloadData = (props) => {
  // useDocumentTitle("Nio Equipment & Engineering | Download Entities");
  const [entity, setEntity] = useState('select');
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    props.getAllProducts()
    .then(tempProducts => {
      setProducts(tempProducts);
    });
    props.getAllCategories()
    .then(tempCategories => {
      setCategories(tempCategories);
    });
  }, [props]);

  const downloadData = () => {
    console.log('Entity selected : ' + entity);
    let selectedEntity;
    if(entity === 'select'){
      props.showSnackMessage({
        vertical: 'top',
        horizontal: 'center',
        snackMessage: 'Select an Entity to download !',
        severity: 'warning'
      });
    }else if(entity === 'Product'){
      console.log('Products', products);
      selectedEntity = products;
    }else if(entity === 'Category'){
      console.log('Category', categories);
      selectedEntity = categories;
    }

    if(selectedEntity){
      downloadFile(JSON.stringify(selectedEntity), entity + "Data.json", "text/plain");
    }
  }
  
  const downloadFile = (content, fileName, contentType) => {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  const setSelectedEntity = (event) => {
    let selectedEntity = event.target.value;
    setEntity(selectedEntity);
    if(selectedEntity === 'Product'){
      console.log('Products', products);
    }else if(selectedEntity === 'Category'){
      console.log('Category', categories);
    }
  }

  const renderDownloadPage = () => {
    return (
      <Box sx={{ m: 5 }}>
        <Typography variant="h4" sx={{ textTransform: 'uppercase' }} display="block" gutterBottom>
          {'Download Entities'}
        </Typography>
        <FormControl fullWidth sx={{ my: 3 }}>
          <InputLabel id="entity-label">Select an Entity</InputLabel>
          <Select
            labelId="entity-label"
            id="entity"
            value={entity}
            onChange={(event) => { setSelectedEntity(event)}}
            label="Select an entity"
          >
            <MenuItem key="select" value='select'>{'Select an Entity'}</MenuItem>
            <MenuItem key="product" value='Product'>{'Product'}</MenuItem>
            <MenuItem key="category" value='Category'>{'Category'}</MenuItem>
          </Select>
          <FormHelperText>Your selected entity will be downloaded.</FormHelperText>
        </FormControl>
        <Button variant="contained" color="primary" sx={{ my: 1 }} onClick={() => { downloadData() }}>
          Download
        </Button>
      </Box>
    );
  };

  return (
    <Container maxWidth="sm">
      {renderDownloadPage()}
    </Container>
  );

};


const mapStateToProps = state => ({
  categories: state.categories,
  products: state.products,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllCategories: CategoriesAction.getAllCategories,
  getAllProducts: ProductsAction.getAllProducts,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DowloadData);