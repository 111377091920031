import React, { Component } from "react";
import { Stack } from "@mui/material";
import { Box, Typography, Button } from "@mui/material";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { LinearProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export default class UploadImages extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);
        this.upload = this.upload.bind(this);
        this.uploadType = props.uploadType ? props.uploadType : 'products';

        this.state = {
            currentFile: undefined,
            previewImage: undefined,
            progress: 0,
            message: "",
            isUploading: false,
            isError: false,
            imageInfos: [],
            uploadButtonText: 'Upload'
        };
    }

    setUploadButtonProps = (text) => {
        this.setState({
            uploadButtonText: text
        });
    };

    componentDidMount() {
        // UploadService.getFiles().then((response) => {
        //   this.setState({
        //     imageInfos: response.data,
        //   });
        // });
    }

    selectFile(event) {
        this.setState({
            currentFile: event.target.files[0],
            previewImage: URL.createObjectURL(event.target.files[0]),
            progress: 0,
            message: "",
        });

        this.props.setUploadStateFromClient({
            currentFile: event.target.files[0],
            previewImage: URL.createObjectURL(event.target.files[0]),
        });
    }

    upload() {
        const storage = getStorage();
        const metadata = {
            contentType: 'image/jpeg',
            cacheControl: 'public,max-age=4000',
        };

        this.setState({
            progress: 0,
            isUploading: true,
        });

        this.setUploadButtonProps('Wait...', 'primary', false);

        const storageRef = ref(storage, 'images/'+ this.uploadType +'/' + this.state.currentFile.name);
        const uploadTask = uploadBytesResumable(storageRef, this.state.currentFile, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                this.setState({
                    progress: progress,
                    isUploading: true,
                });

                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        this.setState({
                            message: 'Upload is paused',
                            isError: true,
                        });
                        break;
                    case 'running':
                        console.log('Upload is running');
                        this.setState({
                            message: '',
                            isError: false,
                        });
                        break;
                    default:
                        break;
                }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        this.setState({
                            progress: 0,
                            currentFile: undefined,
                            message: 'User does not have permission to access the object',
                            isError: true,
                        });
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        this.setState({
                            progress: 0,
                            currentFile: undefined,
                            message: 'User canceled the upload',
                            isError: true,
                        });
                        break;
                    default:
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        this.setState({
                            progress: 0,
                            currentFile: undefined,
                            message: 'Unknown error occurred',
                            isError: true,
                        });
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    this.setState({
                        message: '',
                        isError: false,
                        isUploading: false,
                        imageInfos: downloadURL
                    });

                    setTimeout(() => {
                        this.setState({
                            progress: 0
                        });
                    }, 2000);

                    this.props.setUploadStateFromClient({
                        currentFile: this.state.currentFile,
                        previewImage: this.state.previewImage,
                        downloadURL: downloadURL
                    });

                    this.setUploadButtonProps('Upload');
                });
            }
        );
    }

    render() {
        const {
            currentFile,
            progress,
            message,
            isError,
            isUploading
        } = this.state;

        return (
            <Stack direction={"row"} sx={{ justifyContent: "flex-start", alignItems: 'center', ml: '10px' }}>
                <label htmlFor="btn-upload">
                    <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        onChange={this.selectFile}
                    />
                    <Box sx={{ height: '50px' }}>
                        <Button sx={{ mx: '5px' }} variant="outlined" component="span">
                            Select
                        </Button>
                    </Box>
                </label>
                {message && (
                    <Typography
                        variant="subtitle2"
                        className={`upload-message ${isError ? "error" : ""}`}
                    >{message}
                    </Typography>
                )}
                {progress === 100 ? (
                    <Box color="success.main" sx={{ height: '50px', mt: '7px' }} display="flex">
                        <CheckIcon color="success" />
                        <Typography>Success</Typography>
                    </Box>
                ) : (
                    <>
                        <Box sx={{ height: '50px' }}>
                            <Button
                                color={this.state.uploadButtonTextColor}
                                variant="contained"
                                component="span"
                                sx={{ marginLeft: '5px' }}
                                disabled={!currentFile}
                                onClick={this.upload}
                            >
                                {this.state.uploadButtonText}
                            </Button>
                            {isUploading ? (
                                <LinearProgress sx={{ mt: '5px', ml: '5px' }} variant="determinate" value={progress} />
                            ) : null}
                        </Box>
                    </>
                )}

            </Stack>
        );
    }
}
