import React from 'react';
import { CardHeader } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Container from '@mui/material/Container';

const CorporateVideo = () => {
  return (
      <Container maxWidth="md" disableGutters>
        <Card raised={false}>
          <CardHeader
            title={'Our Corporate Video'}
          />

          <CardActionArea>
            <CardMedia
              component="iframe"
              image="https://www.youtube.com/embed/5iWGWBZ2EFE"
              sx={{
                height : 500
              }}
              // autoPlay='true'
              allow="autoPlay"
              frameborder="0"
            />
            {/* <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                Corporate Video
              </Typography>
            </CardContent> */}
          </CardActionArea>
        </Card>
      </Container>
  );
};

export default CorporateVideo;