import initialState from './../config/initialState';
import ActionTypes from './../actions/ActionTypes';

const categoriesReducer = (state = initialState.categories, actionCreator) => {
    switch (actionCreator.type) {
    	case ActionTypes.LOAD_CATEGORIES:
    		return [...actionCreator.payload.categories];
        case ActionTypes.ADD_CATEGORY:
            let existingCategories = [...state];
            existingCategories.push(actionCreator.payload.category);
            return existingCategories;
        case ActionTypes.UPDATE_CATEGORY:
            let existingCategoriesForUpdate = [...state];
            let updatedCategory = actionCreator.payload.category;
            const index = existingCategoriesForUpdate.findIndex((e) => e.id === updatedCategory.id);

            if (index === -1) {
                existingCategoriesForUpdate.push(updatedCategory);
            } else {
                existingCategoriesForUpdate[index] = updatedCategory;
            }
            return existingCategoriesForUpdate;
        case ActionTypes.DELETE_CATEGORY:
            let existingCategoriesForDelete = [...state];
            let categoryIdToBeDeleted = actionCreator.payload.categoryId;
            existingCategoriesForDelete = existingCategoriesForDelete.filter(item => item.id !== categoryIdToBeDeleted);
            return existingCategoriesForDelete;
        default:
            return state;
    }
}

export default categoriesReducer;