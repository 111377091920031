import React, { Component } from 'react';
import { Box, Button, Container, Stack } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Typography from '@mui/material/Typography';
import { getStorage, ref } from "firebase/storage";
import FormLabel from '@mui/material/FormLabel';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ProductsAction from "../actions/ProductsAction"
import { ConfirmationModal } from "../containers/ConfirmationModal";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ListSubheader from '@mui/material/ListSubheader';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../containers/TabPanel'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ListItemButton from '@mui/material/ListItemButton';

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    if (props.location.state) {
      const storage = getStorage();
      this.state = { ...props.location.state, isConfirmationModalOpen: false, value: 0 }
      const fileReference = ref(storage, props.location.state.productImageURL);
      this.fileName = fileReference.name;
    } else {
      this.state = {
        isConfirmationModalOpen: false,
        value: 0
      };
    }
  }

  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  openConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: true });
  };

  closeConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: false });
  };

  getModalButtons = () => {
    return (
      <React.Fragment>
        <Button variant="outlined" onClick={this.handleDisagree}>Cancel</Button>
        {/* <Button variant="outlined" onClick={()=> {alert('additional action clicked !')}}>Alert</Button> */}
        <Button variant="contained" color="primary" onClick={this.handleAgree}>Delete</Button>
      </React.Fragment>
    )
  }

  handleAgree = async () => {
    console.log(`deleting product - ${this.state.id}.`);
    let apiResult = await this.props.deleteProduct(this.state.id);
    if (apiResult) {
      console.log('Returned from Delete Product API call.')
      this.closeConfirmationModal();
      this.props.history.push('/manage-products');
    }
  }

  handleDisagree = () => {
    console.log('User cancelled delete operation');
    this.closeConfirmationModal();
  }

  showSelectedImage = () => {
    return (
      <Stack sx={{ mx: { xs : 0, md :4}, width: { xs : 'auto', md :400} }}>
        <Avatar
          alt={this.state.name}
          // src={this.state && this.state.productImageURL ? this.state.productImageURL : null}
          src={this.getProductImage(this.state)}
          sx={{ width: { xs : 'auto', md :400}, height: { xs : 'auto', md :400}, bgcolor: 'background.paper', borderRadius: 'inherit' }}
        >
          <CloudUploadOutlinedIcon sx={{ fontSize: 80 }} />
        </Avatar>
        <Typography sx={{ textAlign: 'center' }} variant="body2" display="block" gutterBottom>
          {/* {this.fileName ? this.fileName : 'No file selected'} */}
        </Typography>
      </Stack>
    );
  };

  getProductImage = (product) =>{
    try {
        // Import image on demand
        let productImage;
        if(product.productOfflineImage){
            productImage = require(`assets/images/products/${product.productOfflineImage}`);
        }
        
        // If the image doesn't exist. return null
        if (!productImage) return product.productImageURL;
        return productImage.default;
      } catch (error) {
        console.log(`Image with name "${product.productOfflineImage}" does not exist`);
        return product.productImageURL;
      }
  }

  getFeaturesList = () => {
    let listItems = this.state.features && this.state.features.length > 0 && this.state.features.map((feature, index) => {
      return (
        <ListItem disablePadding key={index} >
          <ListItemIcon>
            <StarBorderIcon color="primary" />
          </ListItemIcon>
          <Stack direction="row" spacing={1} >
            <ListItemText primary={feature} />
          </Stack>
        </ListItem>
      )
    }, this.state.features);

    if (!listItems) {
      listItems = (
        <ListItem disablePadding>
          <ListItemIcon>
            <WarningAmberOutlinedIcon color="warning" />
          </ListItemIcon>
          <ListItemText primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="div"
                variant="body2"
                color="text.primary"
              >
                {'No features have been added yet.'}
              </Typography>
            </React.Fragment>
          } />
        </ListItem>
      )
    }
    return (
      <List
        sx={{ width: '100%', maxWidth: 400, bgcolor: 'transparent' }}
        aria-label="features"
        subheader={
          <Stack direction="row" spacing={1}>
            <ListSubheader component="div" id="nested-list-subheader" sx={{ bgcolor: 'transparent' }}>
              <Typography variant="button" fontSize={16}>
                {'Features'}
              </Typography>
            </ListSubheader>
          </Stack>
        }
      >
        {listItems}
      </List>
    );
  };
  renderSpecifications = () => {
    return (
      <>
        <TableContainer sx={{ width : {xs : 'auto', md : 400}, boxShadow : 3 }} disableGutters component={Paper} >
          <Table sx={{}} aria-label="Specification table">
            <TableBody>
              {this.state.specifications && this.state.specifications.length > 0 && this.state.specifications.map((specification, index) => (
                <TableRow
                  key={specification.index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ width: 200 }}>
                    {specification.specificationName}
                  </TableCell>
                  <TableCell>{specification.specificationValue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ListItem disablePadding sx={{ my: { xs: 3, md: 3 }, display: this.state.specifications && this.state.specifications.length > 0 ? 'none' : 'flex' }} >
          <ListItemButton>
            <ListItemIcon>
              <WarningAmberOutlinedIcon color="warning" />
            </ListItemIcon>
            <ListItemText primary={'No Specifications have been added yet.'} />
          </ListItemButton>
        </ListItem>
      </>
    )
  }
  renderFeaturesList = () => {
    let listItems = this.state.features && this.state.features.length > 0 && this.state.features.map((feature, index) => {
      return (
        <ListItem disablePadding key={index} >
          <ListItemIcon>
            <StarBorderIcon color="primary" />
          </ListItemIcon>
          <Stack direction="row" spacing={1} >
            <ListItemText primary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component="div"
                  variant="body2"
                  color="text.primary"
                >
                  {feature}
                </Typography>
              </React.Fragment>
            } />
          </Stack>
        </ListItem>
      )
    }, this.state.features);

    if (!listItems) {
      listItems = (
        <ListItem disablePadding>
          <ListItemIcon>
            <WarningAmberOutlinedIcon color="warning" />
          </ListItemIcon>
          <ListItemText primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="div"
                variant="body2"
                color="text.primary"
              >
                {'No features have been added yet.'}
              </Typography>
            </React.Fragment>
          } />
        </ListItem>
      )
    }
    return (
      <List
        sx={{ width: '100%', maxWidth: 400, bgcolor: 'transparent' }}
        aria-label="features"
      // subheader={
      //   <Stack direction="row" spacing={1}>
      //     <ListSubheader component="div" id="nested-list-subheader" sx={{ bgcolor: 'transparent' }}>
      //       <Typography variant="button" fontSize={16}>
      //         {'Features'}
      //       </Typography>
      //     </ListSubheader>
      //   </Stack>
      // }
      >
        {listItems}
      </List>
    );
  };

  renderDetailTabs = () => {
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={this.state.value} onChange={this.handleTabChange} aria-label="basic tabs example">
            <Tab label="Features" {...this.a11yProps(0)} />
            <Tab label="Specifications" {...this.a11yProps(1)} />
            {/* <Tab label="Item Three" {...this.a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={this.state.value} index={0}>
          {this.renderFeaturesList()}
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          {this.renderSpecifications()}
        </TabPanel>
        {/* <TabPanel value={this.state.value} index={2}>
          Item Three
        </TabPanel> */}
      </Box>
    );
  };

  getIndianRupeeFormattedAmount = (price) => {
    let priceNumber = Math.floor(price);
    let formattedAmount = priceNumber.toLocaleString('en-IN', {
      maximumFractionDigits: 0,
      style: 'currency',
      currency: 'INR'
    });
    return formattedAmount;
  }

  renderProductDetails = () => {
    return (
      <Container disableGutters>
        <Typography variant="h4" display="block" sx={{ textAlign: {xs : 'center', md : 'left'}, ml: {xs : 0, md : 8}, mt: {xs : 2, md : 2}, textTransform: 'uppercase', color: 'warning.light' }}>
          {this.state.name}
        </Typography>
          <Container sx={{ my: 1 }} disableGutters>
            <Stack sx={{ display : 'flex', flexDirection : {xs : 'column', md: 'row'}}}>
              <Box >
                {this.showSelectedImage()}
              </Box>
              <Box sx={{ mx: 3 }}>
                <Box sx={{ mb: 1 }}>
                  <FormLabel component="legend" sx={{ typography: 'body2' }}>Name</FormLabel>
                  <Typography variant="body1" display="block" gutterBottom>{this.state.name}</Typography>
                </Box>

                <Box sx={{ mb: 1 }}>
                  <FormLabel component="legend" sx={{ typography: 'body2' }}>Category</FormLabel>
                  <Typography variant="body1" display="block" gutterBottom>{this.state.category}</Typography>
                </Box>

                <Box sx={{ mb: 1 }}>
                  <FormLabel component="legend" sx={{ typography: 'body2' }}>Price</FormLabel>
                  <Typography variant="body1" display="block" gutterBottom>{this.getIndianRupeeFormattedAmount(this.state.price)}</Typography>
                </Box>

                <Box sx={{ mb: 1 }}>
                  <FormLabel component="legend" sx={{ typography: 'body2' }}>Description</FormLabel>
                  <Typography variant="body1" display="block" gutterBottom>{this.state.description}</Typography>
                </Box>

                <Box sx={{ mb: 1 }}>
                  <FormLabel component="legend" sx={{ typography: 'body2' }}>Order</FormLabel>
                  <Typography variant="body1" display="block" gutterBottom>{this.state.sortOrder}</Typography>
                </Box>
              </Box>

            </Stack>

            <Box sx={{ mb: 1 }}>
              {this.renderDetailTabs()}
            </Box>

          </Container>

        <Box marginY={1} sx={{ mx: 'auto', my : {xs : 3, md : 5}, display : 'flex', justifyContent:'center' }}>
          <Button onClick={() => { this.props.history.push('/manage-products'); }} variant="outlined" >
            Back
          </Button>
          {this.state ? (
            <>
              { <Button onClick={() => this.openConfirmationModal()} color="secondary" variant="outlined" sx={{ ml: 2 }}>
                Delete
              </Button> }
              { <Button onClick={() => { this.props.history.push('/add-product', this.state); }} variant="contained" color="primary" sx={{ mx: 2 }}>
                Edit
              </Button> }
            </>
          )
            : null}

        </Box>

        <ConfirmationModal
          isOpen={this.state.isConfirmationModalOpen}
          handleClose={() => { this.closeConfirmationModal(); }}
          handleAgree={() => { this.handleAgree(); }}
          handleDisagree={() => { this.handleDisagree(); }}
          customButtons={this.getModalButtons()}
          // agreeButtonText='Yes'
          // disagreeButtonText='No'
          title={'Delete ' + this.state.name}
          subtitle='Are you sure you want to delete this product?' >
        </ConfirmationModal>
      </Container>
    );
  };

  render() {
    return (
      <>
        {this.renderProductDetails()}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteProduct: ProductsAction.deleteProduct,
}, dispatch);

export default connect(null, mapDispatchToProps)(ProductDetails);