import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box, Container } from "@mui/material";
import StarBorderIcon from '@mui/icons-material/StarBorder';

const description = () => {
    return (
        <Box>
            <Typography variant="h6" display="block" sx={{ mb: 2 }} gutterBottom>{'About Us'}</Typography>
            <Typography variant="body2" display="block" sx={{ mb: 2 }} gutterBottom>
                We, <Typography variant="body2" fontWeight={'bold'} component='span' display="inline">Nio Equipment & Engineering</Typography> from 2021 with an affirmation to manufacturing a high-quality range of Scissor and Hydraulic Lift. Offered products range consists of MS Pallet, Scissor Lifts, and Hydraulic Lift Table. All offered products are manufactured as per client needs. More to this, our products are famous for their fine finishing, long service life, easy to use and affordable prices.
            </Typography>
            <Typography variant="body2" display="block" sx={{ mb: 2 }} gutterBottom >
                We are a reputed name in the industry, engaged in the manufacturing, exporting and supplying of a comprehensive assortment of material handling equipments.Our range is demanded worldwide for its high functionality, durability and reliability.

                We have been able to proficiently comprehend the diverse requirements of our clients and accordingly alter our range of material handling systems.
            </Typography>
            <Typography variant="body2" display="block" sx={{ mb: 2 }} gutterBottom >
                Our gamut encompasses Pallet Trucks, Pallet Stackers Floor Crane, Sicssor Lift Table, Dock Leveler, Drum Handling Equipment, Lift For Car, Goods & Personal Use, Portable Gantry & Racks, Trolleys, Castors And Wheels, Fabrication Works and Hydrualic Cylinder, Power Pack.

                To help our clients understand the proper and safe way of operating these material handling equipments, we offer efficient after-sales service in the form of installation training. Owing commitment to quality and client friendly attitude has helped us garner the support of a wide clientele based in different parts of Indian as well as in various countries around the world.
            </Typography>
            <Typography variant="body2" display="block" sx={{ mb: 2 }} gutterBottom >
                We have a highly advanced infrastructure facility. With the help of our team and well-equipped infra, we have been able to reach famous success. Further, to keep our team members updated with the client’s need, we arrange training classes regularly. In adding to this, the use of a wide distribution network by our logistic support has ensured the accurate delivery of this scissor and hydraulic lift range.
            </Typography>
            <Typography variant="body2" display="block" gutterBottom sx={{ mb: 2 }}>
                Under the passionate & thoughtful leadership of <Typography variant="body2" fontWeight={'bold'} component='span' display="inline">"Mrs. Naina Wagh "</Typography>, we are at the top position. She inspires the workforce to channelize all the efforts towards ensuring high client satisfaction.
            </Typography>
        </Box>
    )
}

const basicInformation = () => {
    return (
        <Box>
            <Typography variant="h6" display="block" sx={{ mb: 2 }} gutterBottom>{'Basic Information'}</Typography>
            <TableContainer sx={{ width: "500px", typography: 'body2', mx: 'auto' }} component={Paper}>
                <Table sx={{ width: "500px" }} size="small" aria-label="a dense table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">Nature of Business</TableCell>
                            <TableCell align="left">Manufacturer</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">Additional Business</TableCell>
                            <TableCell align="left">Wholesaler, Supplier, Distributor</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">Company CEO</TableCell>
                            <TableCell align="left">Mrs. Naina Wagh</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">Total Number of Employees</TableCell>
                            <TableCell align="left">26 to 50 People</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">Year of Establishment</TableCell>
                            <TableCell align="left">2021</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">Legal Status of Firm</TableCell>
                            <TableCell align="left">Individual - Proprietor</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">Annual Turnover</TableCell>
                            <TableCell align="left">Rs. 2 - 5 Crores</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

// const financialInformation = () => {
//     return (
//         <Box>
//             <Typography variant="h6" display="block" sx={{ mb: 2 }} gutterBottom>{'Financial Information'}</Typography>
//             <TableContainer sx={{ width: "550px", mx: 'auto' }} component={Paper}>
//                 <Table sx={{ width: "550px" }} size="small" aria-label="a dense table">
//                     <TableBody>
//                         <TableRow>
//                             <TableCell component="th" scope="row">GST Number</TableCell>
//                             <TableCell align="right">27AHEPW8271E1ZY</TableCell>
//                         </TableRow>
//                         <TableRow>
//                             <TableCell component="th" scope="row">Bank</TableCell>
//                             <TableCell align="right">State Bank of India</TableCell>
//                         </TableRow>
//                         <TableRow>
//                             <TableCell component="th" scope="row">Account Type</TableCell>
//                             <TableCell align="right">Current Account</TableCell>
//                         </TableRow>
//                         <TableRow>
//                             <TableCell component="th" scope="row">IFSC Code</TableCell>
//                             <TableCell align="right">SBIN0013527</TableCell>
//                         </TableRow>
//                         <TableRow>
//                             <TableCell component="th" scope="row">Account Name</TableCell>
//                             <TableCell align="right">Nio Equipment And Engineering</TableCell>
//                         </TableRow>
//                         <TableRow>
//                             <TableCell component="th" scope="row">Account Number</TableCell>
//                             <TableCell align="right">40673760853</TableCell>
//                         </TableRow>
//                         <TableRow>
//                             <TableCell component="th" scope="row">Payment Modes</TableCell>
//                             <TableCell align="right">Credit Card, Cash, Cheque, NEFT, Google Pay, Phone Pe</TableCell>
//                         </TableRow>
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//         </Box>
//     )
// }

const aboutUs = () => {
    return (
        <Box>
            <Typography variant="h6" display="block" sx={{ mb: 2 }} gutterBottom>{'Why Us?'}</Typography>
            <Typography variant="body2" display="block" sx={{ mb: 2 }} gutterBottom>
                Over the years, we have evolved as one of the leading manufacturers of the scissor and hydraulic lift. In adding to this, the organization has all facilities for the manufacturing of excellent quality products as per client needs.
            </Typography>

            <Typography variant="h6" display="block" sx={{ mb: 2 }} gutterBottom>{'Our Strengths'}</Typography>
            <List>
                <ListItem component="div" disablePadding>
                    <ListItemIcon>
                        <StarBorderIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="div"
                                variant="body2"
                                color="text.primary"
                            >
                                {'Timely shipment of ordered consignments'}
                            </Typography>
                        </React.Fragment>
                    } />
                </ListItem>
                <ListItem component="div" disablePadding>
                    <ListItemIcon>
                        <StarBorderIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="div"
                                variant="body2"
                                color="text.primary"
                            >
                                {'Easy payment modes'}
                            </Typography>
                        </React.Fragment>
                    } />
                </ListItem>
                <ListItem component="div" disablePadding>
                    <ListItemIcon>
                        <StarBorderIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="div"
                                variant="body2"
                                color="text.primary"
                            >
                                {'Transparent Dealings'}
                            </Typography>
                        </React.Fragment>
                    } />
                </ListItem>
                <ListItem component="div" disablePadding>
                    <ListItemIcon>
                        <StarBorderIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="div"
                                variant="body2"
                                color="text.primary"
                            >
                                {'Client-centric approach'}
                            </Typography>
                        </React.Fragment>
                    } />
                </ListItem>
                <ListItem component="div" disablePadding>
                    <ListItemIcon>
                        <StarBorderIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="div"
                                variant="body2"
                                color="text.primary"
                            >
                                {'Good financial position'}
                            </Typography>
                        </React.Fragment>
                    } />
                </ListItem>
            </List>
        </Box>
    )
}

const AboutUs = () => {
    return (
        <Container maxWidth="md">
            {description()}
            <p />
            {basicInformation()}
            <p />
            {/* {financialInformation()} */}
            <p />
            {aboutUs()}
        </Container>

    );
};

export default AboutUs;