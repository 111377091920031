import React from 'react';
import { Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import useStyles from "../theme/style";
import Link from '@mui/material/Link';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';

const TopHeader = () => {
    const classes = useStyles();
    
    return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        <AppBar position="static" className={classes.topHeader}>
        <Toolbar variant="dense">
        <Typography component="div" sx={{mb:0}} display="block"
            style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
            <MailOutlineIcon fontSize="small" sx={{ mr: 1}} /><Link sx={{fontSize: 14, color:'inherit', textDecoration:'none'}} href="mailto:info@nioequipment.in">{'info@nioequipment.in'}</Link>  
            <Box sx={{ mr: 5}} />
            <PhoneIcon fontSize="small" sx={{ mr: 1}} /> <Link sx={{fontSize: 14, color:'inherit', textDecoration:'none'}} href="tel:+917020611480">{'+91 7020611480'}</Link>
            <PhoneIcon fontSize="small" sx={{ ml: 4, mr:1}} /> <Link sx={{fontSize: 14, color:'inherit', textDecoration:'none'}} href="tel:+919834964986">{'+91 9834964986'}</Link>
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Typography component="div" sx={{mb:0}} display="block" 
            style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}>
                
                <LocalAtmOutlinedIcon fontSize="small" sx={{ mr: 1}} /> <Link sx={{fontSize: 14, color:'inherit', textDecoration:'none'}} target="_blank" href="https://irisgst.com/gstin-filing-detail/?gstinno=27AHEPW8271E1ZY">{'GST : 27AHEPW8271E1ZY'}</Link>
        </Typography>
        </Toolbar>
        </AppBar> 
    </Box>
    );
};

export default TopHeader;