import React, { Component } from 'react';
import { Box, Button, Container, Stack } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Typography from '@mui/material/Typography';
import { getStorage, ref } from "firebase/storage";
import FormLabel from '@mui/material/FormLabel';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CategoriesAction from "./../actions/CategoriesAction"
import { ConfirmationModal } from "../containers/ConfirmationModal";
// import FormGroup from '@mui/material/FormGroup';
// import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';

class CategoryDetails extends Component {
  constructor(props) {
    super(props);
    if (props.location.state) {
      const storage = getStorage();
      this.state = { ...props.location.state, isConfirmationModalOpen: false }
      const fileReference = ref(storage, props.location.state.categoryImageURL);
      this.fileName = fileReference.name;
    } else {
      this.state = {
        isConfirmationModalOpen: false
      };
    }
  }

  openConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: true });
  };

  closeConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: false });
  };

  getModalButtons = () => {
    return (
      <React.Fragment>
        <Button variant="outlined" onClick={this.handleDisagree}>Cancel</Button>
        {/* <Button variant="outlined" onClick={()=> {alert('additional action clicked !')}}>Alert</Button> */}
        <Button variant="contained" color="primary" onClick={this.handleAgree}>Delete</Button>
      </React.Fragment>
    )
  }

  handleAgree = async () => {
    console.log(`deleting product - ${this.state.id}.`);
    let apiResult = await this.props.deleteCategory(this.state.id);
    if (apiResult) {
      console.log('Returned from Delete Category API call.')
      this.closeConfirmationModal();
      this.props.history.push('/categories');
    }
  }

  handleDisagree = () => {
    console.log('User cancelled delete operation');
    this.closeConfirmationModal();
  }

  showSelectedImage = () => {
    return (
      <Stack sx={{ mx: 4, width: 400 }}>
        <Avatar
          // alt="Category Image"
          alt={this.state.name}
          // src={this.state && this.state.categoryImageURL ? this.state.categoryImageURL : null}
          src={this.getCategoryImage(this.state)}
          sx={{ width: 400, height: 400, bgcolor: 'background.paper', borderRadius: 'inherit' }}
        >
          <CloudUploadOutlinedIcon sx={{ fontSize: 80 }} />
        </Avatar>
        <Typography sx={{ textAlign: 'center' }} variant="body2" display="block" gutterBottom>
          {/* {this.fileName ? this.fileName : 'No file selected'} */}
        </Typography>
      </Stack>
    );
  };

  getCategoryImage = (category) =>{
    try {
        // Import image on demand
        let categoryImage;
        if(category.categoryOfflineImage){
            categoryImage = require(`assets/images/categories/${category.categoryOfflineImage}`);
        }
        
        // If the image doesn't exist. return null
        if (!categoryImage) return category.categoryImageURL;
        return categoryImage.default;
      } catch (error) {
        console.log(`Image with name "${category.categoryOfflineImage}" does not exist`);
        return category.categoryImageURL;
      }
  }

  renderCategoryDetails = () => {
    return (
      <Container maxWidth="lg">
        <Typography variant="h4" display="block" sx={{ml:8, textTransform : 'uppercase',color: 'warning.light' }}>
          {this.state.name}
        </Typography>
        <Box my={1} sx={{ display: 'flex' }}>
          <Container sx={{ my: 1 }}>
            {/* <FormGroup row>
              <FormControlLabel
                control={
                  <Switch checked={this.state.gilad} onChange={this.handleChange} name="gilad" />
                }
                label="Gilad Gray"
              />
              <FormControlLabel
                control={
                  <Switch checked={this.state.jason} onChange={this.handleChange} name="jason" />
                }
                label="Jason Killian"
              />
            </FormGroup> */}
            <Stack direction={'row'}>
              <Box marginY={1}>
                {this.showSelectedImage()}
              </Box>
              <Box sx={{width:'100%', my:12}}>
                <Box sx={{ mb: 1 }}>
                  <FormLabel component="legend" sx={{ typography: 'body2' }}>Name</FormLabel>
                  <Typography variant="body1" display="block" gutterBottom>{this.state.name}</Typography>
                </Box>
                <Box sx={{ mb: 1 }}>
                  <FormLabel component="legend" sx={{ typography: 'body2' }}>Description</FormLabel>
                  <Typography variant="body1" display="block" gutterBottom>{this.state.description}</Typography>
                </Box>
                <Box sx={{ mb: 1 }}>
                  <FormLabel component="legend" sx={{ typography: 'body2' }}>Active</FormLabel>
                  <Typography variant="body1" display="block" gutterBottom>{this.state.isActive ? 'Yes' : 'No'}</Typography>
                </Box>
                <Box sx={{ mb: 1 }}>
                  <FormLabel component="legend" sx={{ typography: 'body2' }}>Order</FormLabel>
                  <Typography variant="body1" display="block" gutterBottom>{this.state.sortOrder}</Typography>
                </Box>
              </Box>
            </Stack>
          </Container>
        </Box>

        <Box marginY={1} sx={{  mx:'auto', width:'300px' }}>
          <Button onClick={() => { this.props.history.push('/categories'); }} variant="outlined" >
            Back
          </Button>

          <Button onClick={() => this.openConfirmationModal()} color="secondary" variant="outlined" sx={{ ml: 2 }}>
            Delete
          </Button>

          <Button onClick={() => { this.props.history.push('/add-category', this.state); }} variant="contained" color="primary" sx={{ mx: 2 }}>
            Edit
          </Button>
        </Box>
        <ConfirmationModal
          isOpen={this.state.isConfirmationModalOpen}
          handleClose={() => { this.closeConfirmationModal(); }}
          handleAgree={() => { this.handleAgree(); }}
          handleDisagree={() => { this.handleDisagree(); }}
          customButtons={this.getModalButtons()}
          // agreeButtonText='Yes'
          // disagreeButtonText='No'
          title={'Delete ' + this.state.name}
          subtitle='Are you sure you want to delete this product?' >
        </ConfirmationModal>
      </Container>
    );
  };

  render() {
    return (
      <Box>
        {this.renderCategoryDetails()}
      </Box>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteCategory: CategoriesAction.deleteCategory,
}, dispatch);

export default connect(null, mapDispatchToProps)(CategoryDetails);