import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import { Button, Box } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import { FormControl} from "@mui/material";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { pink } from '@mui/material/colors';
import { useAuth } from '../hooks/useAuth';
import { useHistory } from "react-router-dom";

const SignIn = (props) => {
  const auth = useAuth();
  const history = useHistory();

  const [values, setValues] = React.useState({
    password: '',
    email: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handlePostLoginNavigation = React.useCallback(() => {
    if(props.location.state?.referrer){
      history.push(props.location.state.referrer);
    }else{
      history.push('home');
    }
  }, [history,props.location.state]);

  useEffect(() => {
    console.log(auth.user);
    if(auth.user){
      handlePostLoginNavigation();
    }
  }, [auth.user,handlePostLoginNavigation]);

  const handleLogin = () => {
    auth.signin(values.email, values.password)
      .then((user) => {
        // Signed in 
        props.showSnackMessage({
          vertical: 'top',
          horizontal: 'center',
          snackMessage: 'Login successful for : ' + user.email,
          severity: 'success'
        });
        // handlePostLoginNavigation();
      })
      .catch((error) => {
        // const errorCode = error.code;
        const errorMessage = error.message;
        props.showSnackMessage({
          vertical: 'top',
          horizontal: 'center',
          snackMessage: 'Login failed for user ' + values.email + ' Error : ' + errorMessage,
          severity: 'error'
        });
      });
  };

  return (
    <Container maxWidth="sm" disableGutters
      sx={{
        width: 'auto',
        display: 'block',
        marginTop: { xs: 10, md: 3 },
        padding: { xs: 1, md: 3 },
        [(theme) => theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      }}>
      <CssBaseline />
      <Paper elevation={3}
        sx={{
          margin: { xs: 1, md: 3 },
          padding: { xs: 1, md: 3 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Avatar
          sx={{
            marginTop: { xs: 1, md: 1 },
            marginBottom: { xs: 1, md: 1 },
            bgcolor: pink[500]
          }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box sx={{
          margin: { xs: 1, md: 3 },
        }}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input id="email"
              name="email"
              autoComplete="email"
              value={values.email}
              onChange={handleChange('email')}
              autoFocus />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input name="password"
              type="password"
              id="password"
              value={values.password}
              onChange={handleChange('password')}
              autoComplete="current-password" />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleLogin()}
            sx={{ marginTop: { xs: 1, md: 3 } }}
          >
            Sign in
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default SignIn;