import React, { Component } from 'react';
import { Box, Button, Container, Stack } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Typography from '@mui/material/Typography';
import { getStorage, ref } from "firebase/storage";
import FormLabel from '@mui/material/FormLabel';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ProductsAction from "./../actions/ProductsAction"

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import ListSubheader from '@mui/material/ListSubheader';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../containers/TabPanel'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ListItemButton from '@mui/material/ListItemButton';
import { Helmet } from 'react-helmet';

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    if (props.location.state) {
      const storage = getStorage();
      this.state = { product: this.props.location.state, isConfirmationModalOpen: false, value: 0 }
      const fileReference = ref(storage, props.location.state.productImageURL);
      this.fileName = fileReference.name;
    } else {
      let product = {};
      if (this.props.products && this.props.products.length > 0) {
        product = this.getProductDetailsfromName();
      }

      this.state = {
        product: product,
        isConfirmationModalOpen: false,
        value: 0
      };
    }
  }


  getProductDetailsfromName = () => {
    let product = {};
    let shortProductName = this.props.match.params.productName;
    let productName = shortProductName.replaceAll('-', ' ').toLowerCase();
    let filteredProducts = this.props.products.filter(tempProduct => tempProduct.name.toLowerCase() === productName);
    if (filteredProducts && filteredProducts.length > 0) {
      // this.setState({ product: filteredProducts[0] });
      product = filteredProducts[0];
      // document.title = 'Nio Equipment & Engineering | ' + filteredProducts[0].name;
    }
    return product;
  }

  componentDidMount() {
    // document.title = 'Nio Equipment & Engineering | ' + this.state.product.name;
    if (this.props.products.length === 0) {
      this.props.loadProducts()
        .then(() => {
          // console.log('Returned from Load Products API call.')
          if (this.props.products && this.props.products.length > 0) {
            let product = this.getProductDetailsfromName();
            this.setState({ product: product });
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }

  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  openConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: true });
  };

  closeConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: false });
  };

  getModalButtons = () => {
    return (
      <React.Fragment>
        <Button variant="outlined" onClick={this.handleDisagree}>Cancel</Button>
        {/* <Button variant="outlined" onClick={()=> {alert('additional action clicked !')}}>Alert</Button> */}
        <Button variant="contained" color="primary" onClick={this.handleAgree}>Delete</Button>
      </React.Fragment>
    )
  }

  handleAgree = async () => {
    console.log(`deleting product - ${this.state.product.id}.`);
    let apiResult = await this.props.deleteProduct(this.state.product.id);
    if (apiResult) {
      console.log('Returned from Delete Product API call.')
      this.closeConfirmationModal();
      this.props.history.push('/products');
    }
  }

  handleDisagree = () => {
    console.log('User cancelled delete operation');
    this.closeConfirmationModal();
  }

  showSelectedImage = () => {
    return (
      <Stack sx={{ mx: { xs: 0, md: 4 }, width: { xs: 'auto', md: 400 } }}>
        <Avatar
          // alt="Category Image"
          alt={this.state.product.name}
          // src={this.state && this.state.productImageURL ? this.state.productImageURL : null}
          src={this.getProductImage(this.state.product)}
          sx={{ width: { xs: 'auto', md: 400 }, height: { xs: 'auto', md: 400 }, bgcolor: 'background.paper', borderRadius: 'inherit' }}
        >
          <CloudUploadOutlinedIcon sx={{ fontSize: 80 }} />
        </Avatar>
        <Typography sx={{ textAlign: 'center' }} variant="body2" display="block" gutterBottom>
          {/* {this.fileName ? this.fileName : 'No file selected'} */}
        </Typography>
      </Stack>
    );
  };

  getProductImage = (product) => {
    try {
      // Import image on demand
      let productImage;
      if (product.productOfflineImage) {
        productImage = require(`assets/images/products/${product.productOfflineImage}`);
      }

      // If the image doesn't exist. return null
      if (!productImage) return product.productImageURL;
      return productImage.default;
    } catch (error) {
      console.log(`Image with name "${product.productOfflineImage}" does not exist`);
      return product.productImageURL;
    }
  }

  getFeaturesList = () => {
    let listItems = this.state.product.features && this.state.product.features.length > 0 && this.state.product.features.map((feature, index) => {
      return (
        <ListItem disablePadding key={index} >
          <ListItemIcon>
            <StarBorderIcon color="primary" />
          </ListItemIcon>
          <Stack direction="row" spacing={1} >
            <ListItemText primary={feature} />
          </Stack>
        </ListItem>
      )
    }, this.state.product.features);

    if (!listItems) {
      listItems = (
        <ListItem disablePadding sx={{ my: { xs: 2, md: 2 } }}>
          <ListItemIcon>
            <WarningAmberOutlinedIcon color="warning" />
          </ListItemIcon>
          <ListItemText primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="div"
                variant="body2"
                color="text.primary"
              >
                {'No features have been added yet.'}
              </Typography>
            </React.Fragment>
          } />
        </ListItem>
      )
    }
    return (
      <List
        sx={{ width: '100%', maxWidth: 400, bgcolor: 'transparent' }}
        aria-label="features"
        subheader={
          <Stack direction="row" spacing={1}>
            <ListSubheader component="div" id="nested-list-subheader" sx={{ bgcolor: 'transparent' }}>
              <Typography variant="button" fontSize={16}>
                {'Features'}
              </Typography>
            </ListSubheader>
          </Stack>
        }
      >
        {listItems}
      </List>
    );
  };
  renderSpecifications = () => {
    return (
      <>
        <TableContainer sx={{ width: { xs: 'auto', md: 400 } }} component={Paper} >
          <Table sx={{}} aria-label="Specification table">
            <TableBody>
              {this.state.product.specifications && this.state.product.specifications.length > 0 && this.state.product.specifications.map((specification, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ width: 200 }}>
                    {specification.specificationName}
                  </TableCell>
                  <TableCell>{specification.specificationValue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ListItem disablePadding sx={{ my: { xs: 3, md: 3 }, display: this.state.product.specifications && this.state.product.specifications.length > 0 ? 'none' : 'flex' }} >
          <ListItemButton>
            <ListItemIcon>
              <WarningAmberOutlinedIcon color="warning" />
            </ListItemIcon>
            <ListItemText primary={'No Specifications have been added yet.'} />
          </ListItemButton>
        </ListItem>
      </>
    )
  }
  renderFeaturesList = () => {
    let listItems = this.state.product.features && this.state.product.features.length > 0 && this.state.product.features.map((feature, index) => {
      return (
        <ListItem disablePadding key={index} >
          <ListItemIcon>
            <StarBorderIcon color="primary" />
          </ListItemIcon>
          <Stack direction="row" spacing={1} >
            <ListItemText primary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component="div"
                  variant="body2"
                  color="text.primary"
                >
                  {feature}
                </Typography>
              </React.Fragment>
            } />
          </Stack>
        </ListItem>
      )
    }, this.state.product.features);

    if (!listItems) {
      listItems = (
        <ListItem disablePadding sx={{ my: { xs: 2, md: 2 } }}>
          <ListItemIcon>
            <WarningAmberOutlinedIcon color="warning" />
          </ListItemIcon>
          <ListItemText primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="div"
                variant="body2"
                color="text.primary"
              >
                {'No features have been added yet.'}
              </Typography>
            </React.Fragment>
          } />
        </ListItem>
      )
    }
    return (
      <List
        sx={{ width: '100%', maxWidth: 400, bgcolor: 'transparent' }}
        aria-label="features"
      // subheader={
      //   <Stack direction="row" spacing={1}>
      //     <ListSubheader component="div" id="nested-list-subheader" sx={{ bgcolor: 'transparent' }}>
      //       <Typography variant="button" fontSize={16}>
      //         {'Features'}
      //       </Typography>
      //     </ListSubheader>
      //   </Stack>
      // }
      >
        {listItems}
      </List>
    );
  };

  renderCustomizations = () => {
    let listItems = this.state.product.customizations && this.state.product.customizations.length > 0 && this.state.product.customizations.map((customization, index) => {
      return (
        <ListItem disablePadding key={index} >
          <ListItemIcon>
            <ConstructionOutlinedIcon color="primary" />
          </ListItemIcon>
          <Stack direction="row" spacing={1} >
            <ListItemText primary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component="div"
                  variant="body2"
                  color="text.primary"
                >
                  {customization}
                </Typography>
              </React.Fragment>
            } />
          </Stack>
        </ListItem>
      )
    }, this.state.product.customizations);

    if (!listItems) {
      listItems = (
        <ListItem disablePadding sx={{ my: { xs: 2, md: 2 } }}>
          <ListItemIcon>
            <WarningAmberOutlinedIcon color="warning" />
          </ListItemIcon>
          <ListItemText primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="div"
                variant="body2"
                color="text.primary"
              >
                {'No customizations have been added yet.'}
              </Typography>
            </React.Fragment>
          } />
        </ListItem>
      )
    }
    return (
      <List
        sx={{ width: '100%', maxWidth: 400, bgcolor: 'transparent' }}
        aria-label="features"
      // subheader={
      //   <Stack direction="row" spacing={1}>
      //     <ListSubheader component="div" id="nested-list-subheader" sx={{ bgcolor: 'transparent' }}>
      //       <Typography variant="button" fontSize={16}>
      //         {'Features'}
      //       </Typography>
      //     </ListSubheader>
      //   </Stack>
      // }
      >
        {listItems}
      </List>
    );
  };

  renderDetailTabs = () => {
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={this.state.value} onChange={this.handleTabChange} aria-label="basic tabs example">
            <Tab label="Features" {...this.a11yProps(0)} />
            <Tab label="Specifications" {...this.a11yProps(1)} />
            <Tab label="Customizations" {...this.a11yProps(2)} sx={{ display: this.state.product.customizations ? 'flex' : 'none' }} />
            {/* <Tab label="Item Three" {...this.a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={this.state.value} index={0}>
          {this.renderFeaturesList()}
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          {this.renderSpecifications()}
        </TabPanel>
        <TabPanel value={this.state.value} index={2} sx={{ display: this.state.product.customizations ? 'flex' : 'none' }}>
          {this.renderCustomizations()}
        </TabPanel>
      </Box>
    );
  };

  renderBodyThumbnailMetaTags = () => {
    return (
      <>
        <link itemProp="thumbnailUrl" href={this.state.product.productImageURL} />
        <span itemProp="thumbnail" itemScope itemType="http://schema.org/ImageObject">
          <link itemProp="url" href={this.state.product.productImageURL} />
        </span>
      </>
    );
  }

  renderMetaTags = () => {
    let title = 'Nio Equipment & Engineering : ' + this.state.product.name;
    let description = 'We are leading manufacturer of ' + this.state.product.name + ' from Pune, Maharashtra, India. ' + this.state.product.description;
    let shortProductName = this.props.match.params.productName;
    let ogUrl = 'https://nioequipment.in/product/' + shortProductName;
    let imageUrl = this.state.product.productImageURL;
    if (this.state.product?.productOfflineImage) {
      let imageModule = require(`assets/images/products/${this.state.product.productOfflineImage}`);
      if(imageModule && imageModule.default !== undefined && imageModule.default !== '' ){
        imageUrl = imageModule.default;
      }
    }

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />

        {/* <!-- Site Name, Title, and Description to be displayed --> */}
        <meta property="og:site_name" content="Nio Equipment & Engineering  - Pune, Maharashtra, India" />
        <meta property="og:title" content="Nio Equipment & Engineering  - Pune, Maharashtra, India" />
        <meta property="og:description" content={description} />

        {/* <!-- Image to display --> */}
        <meta property="og:image" itemProp="image primaryImageOfPage" content={imageUrl} />
        <meta property="og:image:secure_url" itemProp="image primaryImageOfPage" content={imageUrl} />

        {/* <!-- No need to change anything here --> */}
        <meta property="og:type" content="website" />
        <meta property="og:image:type" content="image/jpeg" />

        {/* <!-- Size of image. Any size up to 300. Anything above 300px will not work in WhatsApp --> */}
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />

        {/* <!-- Website to visit when clicked in fb or WhatsApp--> */}
        <meta property="og:url" content={ogUrl} />

        <meta property="og:locale" content="en_US" />
        <meta property="og:video" content="https://www.youtube.com/watch?v=yXGyqGFKZDo" />
        <meta property='article:author' content='https://www.facebook.com/nioequipment' />
        <meta property='article:publisher' content='https://www.facebook.com/nioequipment' />
        <meta property="article:modified_time" content="2022-03-02T06:54:31+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nio Equipment & Engineering  - Pune, Maharashtra, India" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={this.state.product.productImageURL} />
        <meta name="twitter:image:width" content="300"/>
        <meta name="twitter:image:height" content="300"/>
        <meta name="googlebot" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"/>
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"/>
        <meta name="keywords" content='Scissor Pallet Trucks, Scissor Pallet Truck, Scissor Lift Tables, Scissor Lift Table, Hydraulic Pallet Trucks, Hydraulic Pallet Truck, Hydraulic Scissor Lift Tables, Hydraulic Scissor Lift Table, Hydraulic Scissor Lifts, Hydraulic Scissor Lift, Hydraulic Stackers, Hydraulic Stacker, Hydraulic Manual Stackers, Hydraulic Manual Stacker, Drum Palletizers, Drum Palletizer, Dock Levelers, Dock Leveler, Dock Leveler Heads, Dock Leveler Head, Goods Lifts, Goods Lift, Single Goods Lifts, Single Goods Lift, Double Goods Lifts, Double Goods Lift, Lift Tables, Lift Table, Hydraulic Dock Ramps, Hydraulic Dock Ramp, Trolleys, Trolley, Hydraulic Brick Trolleys, Hydraulic Brick Trolley, Manual Brick Trolleys, Manual Brick Trolley, Manufacturer, Annual Maintenance Contract(AMC), Manufacturer, Supplier, Annual Maintenance Contract Services, AMC Services, Services Provider, Pune, Maharashtra, Andhra Pradesh, Arunachal Pradesh, Assam, Bihar, Chhattisgarh, Delhi, Goa, Gujarat, Haryana, Himachal Pradesh, Jammu and Kashmir, Jharkhand, Karnataka, Kerala, Madhya Pradesh, Orissa, Punjab, Rajasthan, Sikkim, Tamil Nadu, Tripura, Uttaranchal, Uttar Pradesh, West Bengal, Kolkata, India' />
      </Helmet>
    );
  }

  getIndianRupeeFormattedAmount = (price) => {
    let priceNumber = Math.floor(price);
    let formattedAmount = priceNumber.toLocaleString('en-IN', {
      maximumFractionDigits: 0,
      style: 'currency',
      currency: 'INR'
    });
    return formattedAmount;
  }

  renderProductDetails = () => {
    return (
      <Container disableGutters>
        <Typography variant="h4" display="block" sx={{ textAlign: { xs: 'center', md: 'left' }, ml: { xs: 0, md: 8 }, mt: { xs: 2, md: 2 }, textTransform: 'uppercase', color: 'warning.light' }}>
          {this.state.product.name}
        </Typography>
        <Container sx={{ my: 1 }} disableGutters>
          <Stack sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <Box >
              {this.showSelectedImage()}
            </Box>
            <Box sx={{ mx: 3 }}>
              <Box sx={{ mb: 1 }}>
                <FormLabel component="legend" sx={{ typography: 'body2' }}>Name</FormLabel>
                <Typography variant="body1" display="block" gutterBottom>{this.state.product.name}</Typography>
              </Box>

              <Box sx={{ mb: 1 }}>
                <FormLabel component="legend" sx={{ typography: 'body2' }}>Category</FormLabel>
                <Typography variant="body1" display="block" gutterBottom>{this.state.product.category}</Typography>
              </Box>

              <Box sx={{ mb: 1 }}>
                <FormLabel component="legend" sx={{ typography: 'body2' }}>Price</FormLabel>
                <Typography variant="body1" display="block" gutterBottom>{this.getIndianRupeeFormattedAmount(this.state.product.price)}</Typography>
              </Box>

              <Box sx={{ mb: 1 }}>
                <FormLabel component="legend" sx={{ typography: 'body2' }}>Description</FormLabel>
                <Typography variant="body1" display="block" gutterBottom>{this.state.product.description}</Typography>
              </Box>

            </Box>

          </Stack>

          <Box sx={{ mb: 1 }}>
            {this.renderDetailTabs(this.state.product)}
          </Box>

        </Container>

        <Box marginY={1} sx={{ mx: 'auto', my: { xs: 3, md: 5 }, display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => { this.props.history.push('/home'); }} variant="outlined" >
            Back to Home
          </Button>
        </Box>
      </Container>
    );
  };

  render() {
    return (
      <>
        {this.renderMetaTags()}
        {this.renderBodyThumbnailMetaTags()}
        {this.renderProductDetails()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  products: state.products
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loadProducts: ProductsAction.loadProducts,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);