import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Stack } from "@mui/material";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ProductsAction from "./../actions/ProductsAction"
import { Button } from "@mui/material";
import Container from '@mui/material/Container';
// import useDocumentTitle from 'hooks/useDocumentTitle';

const ProductList = (props) => {
  const history = useHistory();
  // useDocumentTitle("Nio Equipment & Engineering | Material Handling Products");

  useEffect(() => {
    if (props.products.length === 0) {
      let apiResult = props.loadProducts();
      if (apiResult) {
        console.log('Returned from Load Products API call.')
      }
    }
  }, [props]);

  const showDetails = (product) => {
    console.log(`navigate to details ${product.name}.`);
    let shortProductName = product.name.replaceAll(' ', '-').toLowerCase();
    history.push(`/product/${shortProductName}`, product);
  }

  const getIndianRupeeFormattedAmount = (price) => {
    let priceNumber = Math.floor(price);
    let formattedAmount = priceNumber.toLocaleString('en-IN', {
      maximumFractionDigits: 0,
      style: 'currency',
      currency: 'INR'
    });
    return formattedAmount;
  }

  const renderTable = () => {
    return (
      <Box sx={{ mx: 'auto' }}>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{ my: 2 }}>
          <Typography variant="h4" sx={{ textTransform: 'uppercase' }} display="block" gutterBottom>
            {'Products'}
          </Typography>
        </Stack>

        {/* {error && <strong>Error: {error}</strong>}
        {loading && <span>Loading...</span>} */}
        <TableContainer component={Paper} sx={{ maxWidth: 900 }}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell align="left">Product Name</TableCell>
                <TableCell align="left">Category</TableCell>
                {/* <TableCell align="left">Description</TableCell> */}
                <TableCell align="right">Price</TableCell>
                {/* <TableCell align="right">Order</TableCell> */}
                {/* <TableCell align="left">Number of Units Sold</TableCell> */}
                {/* <TableCell align="left">Date Created</TableCell>
                <TableCell align="left">Date Modified</TableCell> */}
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.products.map((product, index) => {
                return (
                  <TableRow
                    key={product.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {/* <img src={product.productImageURL} alt={product.title}/> */}
                      <Avatar
                        alt={product.name}
                        // src={product.productImageURL ? product.productImageURL : null}
                        src={getProductImage(product)}
                        sx={{ width: 64, height: 64 }}
                      >
                        <CloudUploadOutlinedIcon sx={{ fontSize: 80 }} />
                      </Avatar>
                    </TableCell>
                    <TableCell align="left" >{product.name}</TableCell>
                    <TableCell align="left">{product.category}</TableCell>
                    {/* <TableCell align="left">{product.description}</TableCell> */}
                    <TableCell align="right">{getIndianRupeeFormattedAmount(product.price)}</TableCell>
                    {/* <TableCell align="right">{product.sortOrder}</TableCell> */}
                    {/* <TableCell align="left">{product.numberOfUnitsSold}</TableCell> */}
                    {/* <TableCell align="left">{product.dateCreated}</TableCell>
                    <TableCell align="left">{product.dateModified}</TableCell> */}
                    <TableCell align="left">
                      <Stack direction="row" spacing={1} justifyContent={'end'}>
                        <Button size="small" color='primary' variant="contained" startIcon={<ListAltIcon />} onClick={() => showDetails(product)}>
                          View
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  return (
    <Container maxWidth="md">
      {renderTable()}
    </Container>
  );
};

const getProductImage = (product) => {
  try {
    // Import image on demand
    let productImage;
    if (product.productOfflineImage) {
      productImage = require(`assets/images/products/${product.productOfflineImage}`);
    }

    // If the image doesn't exist. return null
    if (!productImage) return product.productImageURL;
    return productImage.default;
  } catch (error) {
    console.log(`Image with name "${product.productOfflineImage}" does not exist`);
    return product.productImageURL;
  }
}

const mapStateToProps = state => ({
  products: state.products
});

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteProduct: ProductsAction.deleteProduct,
  loadProducts: ProductsAction.loadProducts,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);