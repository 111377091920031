import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Avatar from '@mui/material/Avatar';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CategoriesAction from "./../actions/CategoriesAction"
import { ConfirmationModal } from "../containers/ConfirmationModal";
import { Button } from "@mui/material";
import Container from '@mui/material/Container';

const CategoryList = (props) => {
  const history = useHistory();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    if(props.categories.length === 0){
      let apiResult = props.loadCategories();
      if (apiResult) {
        console.log('Returned from Load Categories API call.')
      }
    }
  }, [props]);

  const showDetails = (category) => {
    console.log(`navigate to details ${category.name}.`);
    history.push('/category-details', category)
  }

  /*
  const openConfirmationModal = (category) => {
    setSelectedCategory(category);
    setIsConfirmationModalOpen(true);
  };
  */

  const closeConfirmationModal = () => {
    setSelectedCategory(null);
    setIsConfirmationModalOpen(false);
  };

  const handleAgree = async () => {
    console.log(`deleting category - ${selectedCategory.name}.`);
    let apiResult = await props.deleteCategory(selectedCategory.id);
    if (apiResult) {
      console.log('Returned from Delete Category API call.')
      closeConfirmationModal();
    }
  }

  const handleDisagree = () => {
    console.log('User cancelled delete operation');
    closeConfirmationModal();
  }

  const editSelectedItem = (category) => {
    console.log(`edit to details ${category.name}.`);
    history.push('/add-category', category)
  }

  const renderTable = () => {
    return (
      <Box sx={{ mx: 'auto' }}>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{ my: 2 }}>
          <Typography variant="h4" sx={{ textTransform: 'uppercase' }} display="block" gutterBottom>
            {'CATEGORIES'}
          </Typography>
          <Button onClick={() => { history.push('add-category'); }} variant="contained"  >
            Add Category
          </Button>
        </Stack>

        {/* {error && <strong>Error: {error}</strong>}
        {loading && <span>Loading...</span>} */}
        <TableContainer component={Paper} sx={{ maxWidth: 900 }}>
          <Table size="small" aria-label="simple table" >
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell align="right">Category Name</TableCell>
                {/* <TableCell align="right">Description</TableCell> */}
                <TableCell align="right">Active</TableCell>
                <TableCell align="right">Order</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.categories.map((category, index) => {
                return (
                  <TableRow
                    key={category.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" >
                      {/* <img src={product.productImageURL} alt={product.title}/> */}
                      <Avatar
                        alt={category.name}
                        // src={category.categoryImageURL ? category.categoryImageURL : null}
                        src={getCategoryImage(category)}
                        sx={{ width: 64, height: 64 }}
                      >
                        <CloudUploadOutlinedIcon sx={{ fontSize: 80 }} />
                      </Avatar>
                    </TableCell>
                    <TableCell align="right" >{category.name}</TableCell>
                    {/* <TableCell align="right">{category.description}</TableCell> */}
                    <TableCell align="right">{category.isActive ? 'Yes' : 'No'}</TableCell>
                    <TableCell align="right">{category.sortOrder}</TableCell>
                    <TableCell align="right">
                      <Stack direction="row" spacing={1} justifyContent={'end'}>
                        {/* <Button size="small" color='secondary' variant="outlined" startIcon={<DeleteIcon />} onClick={() => openConfirmationModal(category)}>
                          Delete
                        </Button> */}
                        <Button size="small" variant="outlined" startIcon={<EditIcon />} onClick={() => editSelectedItem(category)}>
                          Modify
                        </Button>
                        <Button size="small" color='primary' variant="contained" startIcon={<ListAltIcon />} onClick={() => showDetails(category)}>
                          View
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          handleClose={() => { closeConfirmationModal() }}
          handleAgree={() => { handleAgree() }}
          handleDisagree={() => { handleDisagree() }}
          agreeButtonText='Delete'
          disagreeButtonText='Cancel'
          title={'Delete ' + (selectedCategory && selectedCategory.name ? selectedCategory.name : 'Product')}
          subtitle='Are you sure you want to delete this category?' >
        </ConfirmationModal>
      </Box>
    )
  }

  return (
    <Container maxWidth="md">
      {renderTable()}
    </Container>
  );
};

const getCategoryImage = (category) =>{
  try {
      // Import image on demand
      let categoryImage;
      if(category.categoryOfflineImage){
          categoryImage = require(`assets/images/categories/${category.categoryOfflineImage}`);
      }
      
      // If the image doesn't exist. return null
      if (!categoryImage) return category.categoryImageURL;
      return categoryImage.default;
    } catch (error) {
      console.log(`Image with name "${category.categoryOfflineImage}" does not exist`);
      return category.categoryImageURL;
    }
}

const mapStateToProps = state => ({
  categories: state.categories
});

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteCategory: CategoriesAction.deleteCategory,
  loadCategories: CategoriesAction.loadCategories,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);