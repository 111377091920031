import React, { Component, Fragment } from 'react';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import { Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';
import * as ProductsAction from "./../actions/ProductsAction"
import * as CategoriesAction from "./../actions/CategoriesAction"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tabs from '@mui/material/Tabs';
import ImageTab from '../containers/ImageTab'
import allProductsCollage from 'assets/images/allProductsCollage.png'
import { ReactCarouselItem } from 'containers/ReactItemCarousel';
import neeHero from "assets/images/banner/NEE-Hero.png";
import { Helmet } from 'react-helmet';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = { value: 0, filteredProducts: [] }
    }

    // async refreshData () {
    //     this.props.getAllProducts()
    //         .then(tempProducts => {
    //             console.log('Got refreshed products', tempProducts);
    //             this.setState({ filteredProducts: tempProducts });
    //         });
    //     this.props.getAllCategories()
    //         .then(tempCategories => {
    //             console.log('Got refreshed categories', tempCategories);
    //         });
    // }

    async componentDidMount() {
        let shouldRefresh = new URL(window.location.href).searchParams.get('refresh');
        if (shouldRefresh === 'true') {
            await this.refreshData();
            // this.props.getAllProducts()
            //     .then(tempProducts => {
            //         console.log('Got refreshed products', tempProducts);
            //         this.setState({ filteredProducts: tempProducts });
            //     });
            // this.props.getAllCategories()
            //     .then(tempCategories => {
            //         console.log('Got refreshed categories', tempCategories);
            //     });
        } else {
            if (this.props.products && this.props.products.length === 0) {
                let apiResult = await this.props.loadProducts();
                if (apiResult) {
                    // console.log('Returned from Products API call.')
                }
            }
            if (this.props.products && this.props.products.length !== 0) {
                this.setState({ filteredProducts: this.props.products });
            }

            if (this.props.categories && this.props.categories.length === 0) {
                let apiResult = await this.props.loadCategories();
                if (apiResult) {
                    // console.log('Returned from Categories API call.')
                }
            }
        }
    }

    refreshData = async () => {
        this.props.getAllProducts()
            .then(tempProducts => {
                console.log('Got refreshed products', tempProducts);
                this.setState({ filteredProducts: tempProducts });
            });
        this.props.getAllCategories()
            .then(tempCategories => {
                console.log('Got refreshed categories', tempCategories);
            });
    }

    

    handleTabChange = (event, newValue) => {
        this.setState({ value: newValue });
        // let selectedCategoryId = event.currentTarget.getAttribute('objectid');
        let selectedCategoryName = event.currentTarget.getAttribute('objectname');
        let tempFilteredProducts = this.props.products;
        if (selectedCategoryName !== 'all') {
            tempFilteredProducts = tempFilteredProducts.filter(item => item.category === selectedCategoryName);
        }
        this.setState({ filteredProducts: tempFilteredProducts });
    };

    onCardClick = (product) => {
        let shortProductName = product.name.replaceAll(' ', '-').toLowerCase();
        this.props.history.push(`/product/${shortProductName}`, product);
    }


    getTruncatedString = (stringToBeTruncated) => {
        return stringToBeTruncated.length > 150 ? stringToBeTruncated.substring(0, 150) + "... Read More" : stringToBeTruncated;
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    renderProductsCarouselsByCategory = () => {
        return (
            <Container disableGutters sx={{ m: 0, display: { xs: 'block', md: 'none' } }}>
                {this.props.categories.map((category, index) => (
                    <Container key={index} disableGutters sx={{ boxShadow: 3, paddingBottom: 1, my: { xs: 3, md: 3 } }}>
                        <Typography
                            // textAlign={'center'}
                            variant="h5"
                            sx={{ mx: 2, py: 1, textTransform: 'uppercase' }}
                            color="text.secondary">
                            {category.name}
                        </Typography>
                        {this.renderProductCarousel(category)}
                    </Container>
                ))}
            </Container>
        );
    }

    renderProductCarousel = selectedCategory => {
        return (
            <Container disableGutters sx={{ display: { xs: 'flex', md: 'none' } }}>
                <ReactCarouselItem
                    numberOfCardsToShow={2}
                    slidesToScroll={1}
                    outsideChevron={false}
                    enableAutoplay={false}
                    showSlither={true}
                    hideChevrons={true}
                    gutter={12}
                    firstAndLastGutter={false}
                    // chevronWidth={40}
                    // infiniteLoop={true}
                    carouselItems={this.getProductsItemsForSelectedCategory(selectedCategory)}
                >
                </ReactCarouselItem>
            </Container>
        );
    }

    getProductsItemsForSelectedCategory = (selectedCategory) => {
        let allProducts = this.props.products;
        let productsForSelectedCategory;
        if (selectedCategory.name !== 'all') {
            productsForSelectedCategory = allProducts.filter(item => item.category === selectedCategory.name);
        }

        let productItems = productsForSelectedCategory.map((product, index) => {
            return (
                <Card key={index}
                    sx={{
                        my: 0,
                        border: 1,
                        borderColor: 'grey.500',
                    }}>
                    <CardActionArea component="a" onClick={() => { this.onCardClick(product) }}>
                        <CardMedia
                            component="img"
                            sx={{
                                width: 180,
                                height: 180,
                                my: 0,
                                mx: 'auto',
                                objectFit: 'cover !important'
                            }}
                            image={this.getProductImage(product)}
                            alt={product.name}
                        />
                        <CardContent sx={{
                            height: '70px',
                            p: 1,
                            bgcolor: '#646464 !important',
                            color: '#fff !important',
                        }}>
                            <Box sx={{ typography: 'subtitle1' }} >
                                {product.name}
                            </Box>
                            <Typography variant="body2"
                                sx={{
                                    display: { xs: 'none', md: 'flex' }, width: '90px'
                                }}
                                color="text.secondary">
                                {this.getTruncatedString(product.description)}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            );
        });
        return productItems;
    }

    getProductImage = (product) => {
        try {
            // Import image on demand
            let productImage;
            if (product.productOfflineImage) {
                productImage = require(`assets/images/products/${product.productOfflineImage}`);
            }

            // If the image doesn't exist. return null
            if (!productImage) return product.productImageURL;
            return productImage.default;
        } catch (error) {
            console.log(`Image with name "${product.productOfflineImage}" does not exist`);
            return product.productImageURL;
        }
    }

    getCategoryImage = (category) => {
        try {
            // Import image on demand
            let categoryImage;
            if (category.categoryOfflineImage) {
                categoryImage = require(`assets/images/categories/${category.categoryOfflineImage}`);
            }

            // If the image doesn't exist. return null
            if (!categoryImage) return category.categoryImageURL;
            return categoryImage.default;
        } catch (error) {
            console.log(`Image with name "${category.categoryOfflineImage}" does not exist`);
            return category.categoryImageURL;
        }
    }

    renderHeroImage = () => {
        return (
            <img src={neeHero} alt="Products offered by Nio Equipment & Engineering" className='neeHero' />
        );
    }

    renderBodyThumbnailMetaTags = () => {
        return (
            <>
                <link itemProp="thumbnailUrl" href='https://nioequipment.in/static/media/NEE-Hero.68884bd4.png' />
                <span itemProp="thumbnail" itemScope itemType="http://schema.org/ImageObject">
                    <link itemProp="url" href='https://nioequipment.in/static/media/NEE-Hero.68884bd4.png' />
                </span>
            </>
        );
    }

    renderMetaTags = () => {
        return (
            <Helmet>
                <title>Nio Equipment & Engineering  - Pune, Maharashtra, India</title>
                <meta name="title" content="Nio Equipment & Engineering  - Pune, Maharashtra, India" />
                <meta name="description" content="We are leading manufacturer of Hydraulic Scissor Lifts, Goods Lift, Goods Elevator, Hydraulic Single Mast Goods Lift, Hydraulic Double Mast Goods Lift, Mobile Dock Ramp, Doc Leveller, Drum Trolley, Platform Trolley, Stair Climbing Trolley & Other Material Handling Equipment from Pune, Maharashtra, India. We serve major cities like Pune, Mumbai, Nagpur, Ahmednagar, Nashik, Delhi, Gurgaon, Ahmedabad, Gujrat, Rajasthan etc." />
                <meta property="og:locale" content="en_US" />
                <meta property="og:title" content="Nio Equipment & Engineering  - Pune, Maharashtra, India" />
                <meta property="og:description" content="We are leading manufacturer of Scissor Lifts, Goods Lift, Mobile Dock Ramp, Doc Leveller, Drum Trolley, Platform Trolley, Stair Climbing Trolley & Other Material Handling Equipment from Pune, Maharashtra, India. We serve major cities like Pune, Mumbai, Nagpur, Ahmednagar, Delhi, Gurgaon, Ahmedabad, Gujrat, Rajasthan etc." />
                <meta property="og:url" content="https://nioequipment.in" />
                <meta property="og:type" content="website" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="300" />
                <meta property="og:image:height" content="300" />
                <meta property="og:image" itemProp="image primaryImageOfPage" content="https://nioequipment.in/images/NEE-Hero.png" />
                <meta property="og:image:secure_url" itemProp="image primaryImageOfPage" content="https://nioequipment.in/images/NEE-Hero.png" />
                {/* <link itemProp="thumbnailUrl" href="https://nioequipment.in/static/media/NEE-Hero.68884bd4.png" />  */}
                <meta property="og:site_name" content="Nio Equipment & Engineering  - Pune, Maharashtra, India" />
                <meta property="og:video" content="https://www.youtube.com/watch?v=yXGyqGFKZDo" />
                <meta property='article:author' content='https://www.facebook.com/nioequipment' />
                <meta property='article:publisher' content='https://www.facebook.com/nioequipment' />
                <meta property="article:modified_time" content="2022-03-02T06:54:31+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Nio Equipment & Engineering  - Pune, Maharashtra, India" />
                <meta name="twitter:description" content="We are leading manufacturer of Scissor Lifts, Goods Lift, Mobile Dock Ramp, Doc Leveller, Drum Trolley, Platform Trolley, Stair Climbing Trolley & Other Material Handling Equipment from Pune, Maharashtra, India. We serve major cities like Pune, Mumbai, Nagpur, Ahmednagar, Delhi, Gurgaon, Ahmedabad, Gujrat, Rajasthan etc." />
                <meta name="twitter:image" content="https://nioequipment.in/static/media/NEE-Hero.68884bd4.png" />
                <meta name="twitter:image:width" content="300" />
                <meta name="twitter:image:height" content="300" />
                <meta name="googlebot" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
                <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
                <meta name="keywords" content='Scissor Pallet Trucks, Scissor Pallet Truck, Scissor Lift Tables, Scissor Lift Table, Hydraulic Pallet Trucks, Hydraulic Pallet Truck, Hydraulic Scissor Lift Tables, Hydraulic Scissor Lift Table, Hydraulic Scissor Lifts, Hydraulic Scissor Lift, Hydraulic Stackers, Hydraulic Stacker, Hydraulic Manual Stackers, Hydraulic Manual Stacker, Drum Palletizers, Drum Palletizer, Dock Levelers, Dock Leveler, Dock Leveler Heads, Dock Leveler Head, Goods Lifts, Goods Lift, Single Goods Lifts, Single Goods Lift, Double Goods Lifts, Double Goods Lift, Lift Tables, Lift Table, Hydraulic Dock Ramps, Hydraulic Dock Ramp, Trolleys, Trolley, Hydraulic Brick Trolleys, Hydraulic Brick Trolley, Manual Brick Trolleys, Manual Brick Trolley, Manufacturer, Annual Maintenance Contract(AMC), Manufacturer, Supplier, Annual Maintenance Contract Services, AMC Services, Services Provider, Pune, Maharashtra, Andhra Pradesh, Arunachal Pradesh, Assam, Bihar, Chhattisgarh, Delhi, Goa, Gujarat, Haryana, Himachal Pradesh, Jammu and Kashmir, Jharkhand, Karnataka, Kerala, Madhya Pradesh, Orissa, Punjab, Rajasthan, Sikkim, Tamil Nadu, Tripura, Uttaranchal, Uttar Pradesh, West Bengal, Kolkata, India' />
            </Helmet>
        );
    }

    renderCategoryTabs = () => {
        const ref = React.createRef();
        return (
            <Box sx={{ width: '100%', display: { xs: 'none', md: 'block', bgcolor: { xs: '#646464 !important', md: '#d3d0d0 !important' } } }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper' }}>
                    <Tabs variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile={true} ref={ref} value={this.state.value} onChange={this.handleTabChange} aria-label="Categories" >
                        <ImageTab
                            key={0}
                            sx={{ p: 0, m: 0, mx: 1 }}
                            index={0}
                            objectid={'all'}
                            objectname={'all'}
                            name={'All'}
                            imageURL={allProductsCollage} />
                        {this.props.categories.map((category, index) => (
                            <ImageTab
                                key={index + 1}
                                sx={{ p: 0, m: 0, mx: 1 }}
                                index={index + 1}
                                objectid={category.id}
                                objectname={category.name}
                                name={category.name}
                                imageURL={this.getCategoryImage(category)} />
                        ))}
                    </Tabs>
                </Box>
                {/* <TabPanel value={this.state.value} index={0}>
                    Item One
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    Item Two
                </TabPanel>
                <TabPanel value={this.state.value} index={2}>
                    Item Three
                </TabPanel> */}
                <Container disableGutters>
                    <Box sx={{ flexGrow: 1, my: { xs: 1, md: 8 } }}>
                        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {this.state.filteredProducts.map((product, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}
                                    sx={{ mt: { xs: 1, md: 0 } }}>
                                    <Card key={index}
                                        sx={{
                                            mx: { xs: 1, md: 0 },
                                            my: { xs: 0, md: 1 },
                                            maxWidth: { xs: 'auto', md: '370px' },
                                            height: { xs: '250px', md: '500px' }
                                        }}>
                                        <CardActionArea component="a" onClick={() => { this.onCardClick(product) }}>
                                            <CardMedia
                                                component="img"
                                                sx={{
                                                    height: { xs: '180px', md: '350px' },
                                                    width: { xs: '180px', md: '350px' }
                                                }}
                                                image={this.getProductImage(product)}
                                                alt={product.name}
                                            />
                                            <CardContent sx={{
                                                height: { xs: '70px', md: '340px' },
                                                p: { xs: 1, md: 2 },
                                                bgcolor: { xs: '#646464 !important', md: '#d3d0d0 !important' },
                                                color: { xs: '#fff !important', md: '#080808c7 !important' },
                                                // color : (theme) => theme.palette.primary.contrastText
                                            }}>
                                                <Box sx={{ typography: { xs: 'subtitle1', md: 'h5' } }} >
                                                    {product.name}
                                                </Box>
                                                <Typography variant="body2"
                                                    sx={{
                                                        display: { xs: 'none', md: 'flex' }, width: { xs: '90px', md: '330px' }
                                                    }}
                                                    color="text.secondary">
                                                    {this.getTruncatedString(product.description)}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Container>

            </Box>
        );
    }

    render() {
        return (
            <>
                {this.renderMetaTags()}
                {this.renderBodyThumbnailMetaTags()}
                <Container maxWidth="lg" disableGutters>
                    {/* {this.renderHeroImage()} */}
                    {this.renderCategoryTabs()}
                    {this.renderProductsCarouselsByCategory()}
                </Container>
            </>
        );
    }
}

/******************** Redux Bindings ********************/
const mapStateToProps = state => ({
    products: state.products,
    categories: state.categories,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadProducts: ProductsAction.loadProducts,
    loadCategories: CategoriesAction.loadCategories,
    getAllCategories: CategoriesAction.getAllCategories,
    getAllProducts: ProductsAction.getAllProducts
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);