import React from 'react';

const NotFound = () => {
    return (
        <div id="wrapper">
            {/* <img src="https://i.imgur.com/qIufhof.png" alt="This page could not be found" /> */}
            <img src="https://miro.medium.com/max/1400/1*DeBkx8vjbumpCO-ZkPE9Cw.png" alt="This page could not be found" width={"80%"} height={"80%"} />
            {/* <img src="https://miro.medium.com/max/1400/1*EQisBuMOijQT8woW0Jn6pA.jpeg" alt="This page could not be found" width={"80%"} height={"80%"} /> */}
            <div id="info">
                {/* <h3>This page could not be found</h3> */}
            </div>
        </div >
    )
};

export default NotFound;