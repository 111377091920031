import { createTheme, responsiveFontSizes  } from '@mui/material/styles';
import PoppinsRegular from './../assets/fonts/Poppins/Poppins-Regular.ttf'
import PoppinsLight from './../assets/fonts/Poppins/Poppins-Light.ttf'
import PoppinsMedium from './../assets/fonts/Poppins/Poppins-Medium.ttf'
import PoppinsSemiBold from './../assets/fonts/Poppins/Poppins-SemiBold.ttf'
import PoppinsBold from './../assets/fonts/Poppins/Poppins-Bold.ttf'

let customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'PoppinsRegular, PoppinsLight, PoppinsMedium, PoppinsSemiBold, PoppinsBold, Arial',
    // fontSize: customTheme.breakpoints.sm ? 12 : 14,
    // htmlFontSize: 16,
  },
  palette: {
    // primary: {
    //   main: "#fbfbfb"
    // },
    darkBlack: {
      main: '#333'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {

        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'PoppinsRegular';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('PoppinsRegular'), local('Poppins-Regular'), url(${PoppinsRegular}) format('truetype');
        },
        @font-face {
          font-family: 'PoppinsLight';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('PoppinsLight'), local('Poppins-Light'), url(${PoppinsLight}) format('truetype');
        },
        @font-face {
          font-family: 'PoppinsMedium';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('PoppinsMedium'), local('Poppins-Medium'), url(${PoppinsMedium}) format('truetype');
        },
        @font-face {
          font-family: 'PoppinsSemiBold';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('PoppinsSemiBold'), local('Poppins-SemiBold'), url(${PoppinsSemiBold}) format('truetype');
        },
        @font-face {
          font-family: 'PoppinsBold';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('PoppinsBold'), local('Poppins-Bold'), url(${PoppinsBold}) format('truetype');
        }
      `,
    },
  }
});

customTheme = createTheme(customTheme, {
  typography: {
    fontSize: customTheme.breakpoints.sm ? 12 : 14,
    // htmlFontSize: 16,
  },
});

customTheme = responsiveFontSizes(customTheme);

export default customTheme;