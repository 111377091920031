import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBc8q0C5VmDztfQcgN2Lgj8J9O9OZSzGyM",
  authDomain: "nee-store-app.firebaseapp.com",
  databaseURL: "https://nee-store-app-default-rtdb.firebaseio.com",
  projectId: "nee-store-app",
  storageBucket: "nee-store-app.appspot.com",
  messagingSenderId: "1013203015652",
  appId: "1:1013203015652:web:e79670fa6743205e0499f1"
 };

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
