import React, { useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Stack, Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Typography from '@mui/material/Typography';


const CustomTableCell = ({ row, name, label, onSpecificationChange, selectedRowId }) => {
  const isEditMode = row.id === selectedRowId;
  return (
    <TableCell align="left">
      {isEditMode ? (
        <TextField
          value={row[name]}
          name={name}
          onChange={event => onSpecificationChange(event, row)}
          variant="standard"
          label={row[name] && row[name] !== '' ? '' : label}
        // autoFocus={row[name] && row[name] !== '' ? false : true}
        />
      ) : (
        <Typography component={'div'} variant="body2" display="block" gutterBottom>{row[name]}</Typography>
      )}
    </TableCell>
  );
};

export default function SpecificationsTable(props) {
  useEffect(() => {
    if (props.specifications && props.specifications.length > 0) {
      let specificationRows = props.specifications.map((row, index) => {
        return createData(index, row.specificationName, row.specificationValue);
      });
      setRows(specificationRows);
    }
  }, [props.specifications]);

  const getSpecification = (specificationName, specificationValue) => ({
    specificationName,
    specificationValue
  });

  const createData = (index, specificationName, specificationValue) => ({
    id: specificationName.replace(" ", "_") + index,
    specificationName,
    specificationValue
  });

  const addSpecification = () => {
    console.log('addSpecification');
    let tempRows = getNonBlankRows();
    tempRows.push(createData(tempRows.length, '', ''));
    setSelectedRowId(null);
    setRows(tempRows);
    setSelectedRowId("" + (tempRows.length - 1));
  }

  const [rows, setRows] = React.useState([]);
  const [previousRows, setPreviousRows] = React.useState({});
  const [selectedRowId, setSelectedRowId] = React.useState(null);

  

  const onSpecificationChange = (event, row) => {
    if (!previousRows[row.id] && row.specificationName !== '' && row.specificationValue !== '') {
      setPreviousRows(state => ({ ...state, [row.id]: row }));
    }
    const name = event.target.name;
    const value = event.target.value;
    const { id } = row;
    const newRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const getNonBlankRows = () => {
    let existingRows = [...rows];
    let newRows = existingRows.filter((rowItem) => {
      if (rowItem.specificationName === '' && rowItem.specificationValue === '') {
        return undefined;
      } else {
        return { ...rowItem };;
      }
    });
    return newRows;
  }

  const updateSpecification = id => {
    setSelectedRowId(null);
    let existingRows = [...rows];
    let newRows = existingRows.filter((rowItem) => {
      if (rowItem.id === id) {
        if (rowItem.specificationName === '' && rowItem.specificationValue === '') {
          return undefined;
        } else {
          return { ...rowItem };;
        }
      } else {
        if (rowItem.specificationName === '' && rowItem.specificationValue === '') {
          return undefined;
        } else {
          return { ...rowItem };;
        }
      }
    });
    setRows(newRows);
    pushSpecificationsToParent(newRows);
    setPreviousRows(state => {
      delete state[id];
      return state;
    });
  };

  const pushSpecificationsToParent = (updatedRows) => {
    console.log('pushSpecificationsToParent');
    let specifications = updatedRows.map(row => {
      return getSpecification(row.specificationName, row.specificationValue);
    });
    props.onSpecificationsUpdate(specifications);
  }

  const switchToEditableMode = selectedRow => {
    let selectedRowId = selectedRow.id;
    let tempRows = getNonBlankRows();
    setRows(tempRows);
    setSelectedRowId(selectedRowId);
    if (!previousRows[selectedRowId]) {
      setPreviousRows(state => ({ ...state, [selectedRowId]: selectedRow }));
    }
  };

  const cancelEditMode = selectedRowId => {
    setSelectedRowId(null);
    let existingRows = [...rows];
    let newRows = existingRows.filter((rowItem) => {
      if (rowItem.id === selectedRowId) {
        if (previousRows[selectedRowId]) {
          let originalRow = previousRows[selectedRowId];
          return originalRow;
        } else {
          return undefined;
        }
      } else {
        if (rowItem.specificationName === '' && rowItem.specificationValue === '') {
          return undefined;
        } else {
          return { ...rowItem };
        }
      }
    });

    setRows(newRows);

    if (previousRows[selectedRowId]) {
      setPreviousRows(state => {
        delete state[selectedRowId];
        return state;
      });
    }
  };

  const onDelete = selectedRowId => {
    console.log('onDelete');
    let existingRows = getNonBlankRows();
    let newRows = existingRows.filter(row => row.id !== selectedRowId);
    setRows(newRows);
    setSelectedRowId(null);
    pushSpecificationsToParent(newRows);
  };

  return (
    <>
      <Button color="primary" startIcon={<AddCircleIcon />} onClick={() => { addSpecification() }}>
        Add new specification
      </Button>
      <Paper
        sx={{
          mt: 3,
          display: rows && rows.length > 0 ? 'block' : 'none'
        }}>
        <Table aria-label="Specifications" >
          <TableHead>
            <TableRow>
              <TableCell align="left">Specification</TableCell>
              <TableCell align="left">Value</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <CustomTableCell {...{ row, name: "specificationName", label: 'Specification', onSpecificationChange, selectedRowId }} />
                <CustomTableCell {...{ row, name: "specificationValue", label: 'Value', onSpecificationChange, selectedRowId }} />
                <TableCell>
                  {row.id === selectedRowId ? (
                    <Stack direction="row" justifyContent={'end'}>
                      <IconButton
                        aria-label="done"
                        onClick={() => updateSpecification(row.id)}
                      >
                        <DoneOutlinedIcon color='primary' />
                      </IconButton>
                      <IconButton
                        aria-label="cancel"
                        onClick={() => cancelEditMode(row.id)}
                      >
                        <CancelOutlinedIcon color='secondary' />
                      </IconButton>
                    </Stack>
                  ) : (
                    <Stack direction="row" justifyContent={'end'}>
                      <IconButton
                        aria-label="edit"
                        disabled={(row.id !== selectedRowId && selectedRowId === null) ? false : true}
                        onClick={() => switchToEditableMode(row)}
                      >
                        <EditIcon color={(row.id !== selectedRowId && selectedRowId === null) ? 'primary' : 'disabled'} />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        disabled={(row.id !== selectedRowId && selectedRowId === null) ? false : true}
                        onClick={() => onDelete(row.id)}
                      >
                        <DeleteIcon color={(row.id !== selectedRowId && selectedRowId === null) ? 'secondary' : 'disabled'} />
                      </IconButton>
                    </Stack>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <ListItem disablePadding sx={{ my: { xs: 3, md: 3 }, display: rows && rows.length > 0 ? 'none' : 'flex' }} >
        <ListItemButton>
          <ListItemIcon>
            <WarningAmberOutlinedIcon color="warning" />
          </ListItemIcon>
          <ListItemText primary={'No Specifications have been added yet.'} />
        </ListItemButton>
      </ListItem>
    </>
  );
}