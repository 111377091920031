// import data from '../data/data.json'
import productsData from 'data/ProductData.json'
import categoriesData from 'data/CategoryData.json'
const initialState = {
  // products: data.testProducts,
  // categories : data.testCategories,
  // products: [],
  // categories: [],
  products: productsData,
  categories: categoriesData,
}

export default initialState;
