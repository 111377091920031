import React from "react";
import "./App.css";
import customTheme from "./theme/theme";
import { ThemeProvider } from '@mui/material/styles';
import Header from "./components/Header";
import TopHeader from "./components/TopHeader";
import Footer from "./components/Footer";
import Router from "./routes/router";
import CssBaseline from '@mui/material/CssBaseline';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ScrollTop from './containers/ScrollTop'
import Fab from '@mui/material/Fab';
import { Box } from "@mui/system";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { ProvideAuth } from "./hooks/useAuth";
import { Provider } from 'react-redux';
import store from "./config/store";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Button } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import { Container } from "@mui/material";
import ChatIcon from '@mui/icons-material/Chat';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import { Helmet } from 'react-helmet';

function App(props) {
  // const auth = useAuth();

  // const [isMobile, setIsMobile] = useState(useMediaQuery(customTheme.breakpoints.only('xs'))); 
  // const [isDesktop, setIsDesktop] = useState(useMediaQuery(customTheme.breakpoints.up('md')));
  // console.log('mobile - '+ useMediaQuery(customTheme.breakpoints.only('xs')));
  // console.log(customTheme.breakpoints.only('xs'));
  // console.log('desktop - '+ useMediaQuery(customTheme.breakpoints.up('md')));
  // console.log(customTheme.breakpoints.up('md'));
  const isMobile = useMediaQuery('@media (min-width:0px) and (max-width:599.95px)');

  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const handleSpeedDialOpen = () => setOpenSpeedDial(true);
  const handleSpeedDialClose = () => setOpenSpeedDial(false);

  const connectToWhatsApp = () => {
    window.open('https://api.whatsapp.com/send?phone=+917020611480&text=Hi!%20I%27m%20interested%20in%20one%20of%20your%20products.%20Let%27s%20connect%20to%20discuss%20the%20details.', '_blank', 'noopener,noreferrer');
  }

  const dialCall = () => {
    window.open('tel:+917020611480', '_self');
  }

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    snackMessage: 'I love Snacks',
    severity: 'success' //error, warning, info, success
  });

  const { vertical, horizontal, open, snackMessage, severity } = state;

  function showSnackMessage(newState) {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


  const renderMetaTags = () => {
    let description = 'We are leading manufacturer of Scissor Lifts, Goods Lift, Mobile Dock Ramp, Doc Leveller, Drum Trolley, Platform Trolley, Stair Climbing Trolley & Other Material Handling Equipment from Pune, Maharashtra, India. We serve major cities like Pune, Mumbai, Nagpur, Ahmednagar, Delhi, Gurgaon, Ahmedabad, Gujrat, Rajasthan etc.';
    return (
      <Helmet>
        <title>Nio Equipment & Engineering : Manufacturer of Material Handling Equipment from Pune, Maharashtra, India.</title>
        <meta name="title" content="Nio Equipment & Engineering : Manufacturer of Material Handling Equipment from Pune, Maharashtra, India." />
        <meta name="description" content={description} />

        {/* <!-- Image to display --> */}
        <meta property="og:image" itemProp="image primaryImageOfPage" content='https://nioequipment.in/images/NEE-Hero.png' />
        <meta property="og:image:secure_url" itemProp="image primaryImageOfPage" content="https://nioequipment.in/images/NEE-Hero.png" />

        {/* <!-- No need to change anything here --> */}
        <meta property="og:type" content="website" />
        <meta property="og:image:type" content="image/jpeg" />

        {/* <!-- Size of image. Any size up to 300. Anything above 300px will not work in WhatsApp --> */}
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />

        {/* <!-- Website to visit when clicked in fb or WhatsApp--> */}
        <meta property="og:url" content='https://nioequipment.in' />

        {/* <!-- Site Name, Title, and Description to be displayed --> */}
        <meta property="og:site_name" content="Nio Equipment & Engineering  - Pune, Maharashtra, India" />
        <meta property="og:title" content="Nio Equipment & Engineering  - Pune, Maharashtra, India" />
        <meta property="og:description" content={description} />

        <meta property="og:locale" content="en_US" />
        <meta property="og:video" content="https://www.youtube.com/watch?v=yXGyqGFKZDo" />
        <meta property='article:author' content='https://www.facebook.com/nioequipment' />
        <meta property='article:publisher' content='https://www.facebook.com/nioequipment' />
        <meta property="article:modified_time" content="2022-03-02T06:54:31+00:00" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nio Equipment & Engineering  - Pune, Maharashtra, India" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content='https://nioequipment.in/static/media/NEE-Hero.68884bd4.png' />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta name="googlebot" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <meta name="keywords" content='Scissor Pallet Trucks, Scissor Pallet Truck, Scissor Lift Tables, Scissor Lift Table, Hydraulic Pallet Trucks, Hydraulic Pallet Truck, Hydraulic Scissor Lift Tables, Hydraulic Scissor Lift Table, Hydraulic Scissor Lifts, Hydraulic Scissor Lift, Hydraulic Stackers, Hydraulic Stacker, Hydraulic Manual Stackers, Hydraulic Manual Stacker, Drum Palletizers, Drum Palletizer, Dock Levelers, Dock Leveler, Dock Leveler Heads, Dock Leveler Head, Goods Lifts, Goods Lift, Single Goods Lifts, Single Goods Lift, Double Goods Lifts, Double Goods Lift, Lift Tables, Lift Table, Hydraulic Dock Ramps, Hydraulic Dock Ramp, Trolleys, Trolley, Hydraulic Brick Trolleys, Hydraulic Brick Trolley, Manual Brick Trolleys, Manual Brick Trolley, Manufacturer, Annual Maintenance Contract(AMC), Manufacturer, Supplier, Annual Maintenance Contract Services, AMC Services, Services Provider, Pune, Maharashtra, Andhra Pradesh, Arunachal Pradesh, Assam, Bihar, Chhattisgarh, Delhi, Goa, Gujarat, Haryana, Himachal Pradesh, Jammu and Kashmir, Jharkhand, Karnataka, Kerala, Madhya Pradesh, Orissa, Punjab, Rajasthan, Sikkim, Tamil Nadu, Tripura, Uttaranchal, Uttar Pradesh, West Bengal, Kolkata, India' />
      </Helmet>
    );
  }

  const getSnackBar = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        // message={snackMessage}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {snackMessage}
        </Alert>
      </Snackbar>
    );
  }

  const toggleChatPlugin = () => {
    console.log('toggleChatPlugin');

    if (isMobile) {
      const facebookChat = document.querySelector(".fb_dialog");
      const isFbCPluginHidden = facebookChat !== undefined && facebookChat !== null && facebookChat.style.display === 'none';
      if (isFbCPluginHidden) {
        if (typeof window !== "undefined") {
          if (typeof window.FB !== "undefined") {
            window.FB.CustomerChat.show();
          }
        }
      } else {
        if (typeof window !== "undefined") {
          if (typeof window.FB !== "undefined") {
            window.FB.CustomerChat.hide();
          }
        }
      }
    } else {
      window.Tawk_API.toggle();
    }
  }

  return (
    <Provider store={store}>
      <ProvideAuth>
        <ThemeProvider theme={customTheme}>
          <div className="neeAppRoot">
            {renderMetaTags()}
            <React.Fragment>
              <CssBaseline />
              <TopHeader />
              <Header showSnackMessage={showSnackMessage} />
              <Box id="back-to-top-anchor" sx={{ minHeight: '0' }} />
              {getSnackBar()}
              <Container disableGutters sx={{
                marginRight: 'auto',
                marginLeft: 'auto',
                minHeight: '650px !important',
              }}>
                <Router showSnackMessage={showSnackMessage}></Router>
              </Container>
              <Footer />
              <ScrollTop {...props}>
                <Fab color="secondary" aria-label="scroll back to top" sx={{ bottom: 180, m: 0, mr: '12px', }}>
                  <KeyboardArrowUpIcon />
                </Fab>
              </ScrollTop>
              {/* <Tooltip title='Click to Call' placement="top">
                <Fab onClick={() => { window.open('tel:+917020611480', '_self') }} sx={{ m: 1, ml: 4, color: 'primary.contrastText', position: 'fixed', bottom: 180, bgcolor: '#2196f3', "&:hover": { backgroundColor: "#1565c0" } }}>
                  <Avatar sx={{ bgcolor: 'inherit',"&:hover": { backgroundColor: "inherit" } }}>
                    <CallIcon fontSize="large" />
                  </Avatar>
                </Fab>
              </Tooltip> */}
              {/* <Tooltip title='Connect on Whatsapp' placement="top">
                <Fab onClick={() => { window.open('https://api.whatsapp.com/send?phone=+917020611480&text=Hi!%20I%27m%20interested%20in%20one%20of%20your%20products.%20Let%27s%20connect%20to%20discuss%20the%20details.', '_blank', 'noopener,noreferrer') }} sx={{ m: 2, ml: 4, color: 'primary.contrastText', position: 'fixed', bottom: 0, bgcolor: '#88d651' }}>
                  <Avatar sx={{ bgcolor: '#88d651' }}>
                    <WhatsAppIcon fontSize="large" />
                  </Avatar>
                </Fab>
              </Tooltip> */}
              <Button
                variant="contained"
                onClick={() => { toggleChatPlugin() }}
                sx={{
                  m: 2,
                  color: 'primary.contrastText',
                  position: 'fixed',
                  bottom: 0,
                  right: 0,
                  bgcolor: '#0A7CFF',
                  border: 1,
                  borderColor: '#0A7CFF',
                  width: 130,
                  height: 44,
                  borderRadius: '50px',
                  boxShadow: 3
                }}
                startIcon={<ChatIcon sx={{ fontSize: "30px!important" }} />}>
                <Typography component="div" variant="subtitle" fontSize='17px' sx={{ textTransform: 'capitalize' }} >
                  Ask Us
                </Typography>
              </Button>

              <SpeedDial
                ariaLabel="Speed Dial"
                sx={{ ml: 2, color: 'primary.contrastText', position: 'fixed', bottom: 0 }}
                FabProps={{ size: "medium" }}
                icon={<PermPhoneMsgIcon fontSize="large" />}
                onClose={handleSpeedDialClose}
                onOpen={handleSpeedDialOpen}
                direction='right'
                open={openSpeedDial}
              >
                <SpeedDialAction
                  icon={<WhatsAppIcon sx={{ color: 'primary.contrastText' }} fontSize='large' />}
                  tooltipTitle={'Connect on WhatsApp'}
                  tooltipPlacement={'top'}
                  sx={{ width: { xs: '48px', md: '56px' }, height: { xs: '48px', md: '56px' }, bgcolor: '#88d651', "&:hover": { backgroundColor: "#70c435" } }}
                  onClick={() => { handleSpeedDialClose(); connectToWhatsApp(); }}
                />
                <SpeedDialAction
                  icon={<CallIcon sx={{ color: 'primary.contrastText' }} fontSize='large' />}
                  tooltipTitle={'Call us'}
                  tooltipPlacement={'top'}
                  disableRipple={true}
                  disableFocusRipple={true}
                  sx={{ width: { xs: '48px', md: '56px' }, height: { xs: '48px', md: '56px' }, bgcolor: '#2196f3', "&:hover": { backgroundColor: "#1565c0" } }}
                  onClick={() => { handleSpeedDialClose(); dialCall(); }}
                />
              </SpeedDial>
            </React.Fragment>
          </div>
        </ThemeProvider>
      </ProvideAuth>
    </Provider>
  );
}

export default App;
